import React from "react";
import { messageType } from "../../components/Chat";
import { MessageStartInfoScreen } from "./MessageStartInfo";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import VideoGroupChat from "../../components/VideoGroupChat";
import { MessagesChatScreen } from "./MessagesChat";
import { AudioMessageInfoScreen } from "./AudioMessageInfo";


export function VisGroupDone(props: ScreenProps) {
  return (
    <VideoGroupChat
      videoSrc="chapter2/Capitolo_2_06_mex a Vis.mp4"
      sender={"ANTONIO, YOUSSEF, VIS"}
      receiver={"ALESSANDRO"}
      messages={messages}
      phoneDelay={10}
      canContinue={() => props.onNext(AudioMessageInfoScreen)}
    />
  );
}

export const VisGroupDoneScreen: ScreenElement = {
  fn: VisGroupDone,
};

const messages: messageType[] = [
  {
    txt: chapter2Text["cap02-05_txt02"].text,
    sender: chapter2Text["cap02-05_txt02"].chara
  },
  {
    txt: chapter2Text["cap02-05_txt03"].text,
    sender: chapter2Text["cap02-05_txt03"].chara
  },
]