import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { NervousChatScreen } from "./NervousChat";
import { OnlyYoussefScreen } from "./OnlyYoussef";


function VisChatInfo(props:ScreenProps){
  return (
    <InfoPage
        continue={() => props.onNext(OnlyYoussefScreen)}
        text={chapter3Text["cap03-12_txt03"].text}
      />
  )
};

export const VisChatInfoScreen: ScreenElement = {
fn: VisChatInfo
}
