import { useAtomValue } from "jotai";
import React from "react"
import { liedToVisBoxAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import { PostVisOutcomeScreen } from "./PostVisOutcome";

function VisBoxOutcome(props: ScreenProps) {
    const liedToVis = useAtomValue(liedToVisBoxAtom);
    return (
        <Video
            videoEnded={() => props.onNext(PostVisOutcomeScreen)}
            videoSrc={require(`../../assets/videos/chapter5/${liedToVis ? "cap05-02-option-01_video.mp4" : "cap05-02-option-02_video.mp4"}`)}
        />
    )
};

export const VisBoxOutcomeScreen: ScreenElement = {
    fn: VisBoxOutcome
}
