import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { SmallGroupChat2Screen } from "./SmallGroupChat2";
import { CopChoiceScreen } from "./CopChoice";


function CopInfo(props:ScreenProps){
  return (
    <InfoPage
        continue={() => props.onNext(CopChoiceScreen)}
        text={chapter3Text["cap03-09_txt01"].text}
      />
  )
};

export const CopInfoScreen: ScreenElement = {
fn: CopInfo
}
