import React from "react"
import Video from "../../components/Video"
import { VisChatChoiceScreen } from "./VisChatChoice"

function NoiseNotWait(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(VisChatChoiceScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_scappano.mp4`)}
        />
    )
};

export const NoiseNotWaitScreen: ScreenElement = {
    fn: NoiseNotWait
}
