import { useAtomValue } from "jotai"
import React, { useEffect, useState } from "react"
import { endingAtom } from "../atoms/endingAtom"
import InfoPage from "./InfoPage";
import endingText from "../assets/texts/Endings.json";
import { TitleScreen } from "./TitlePage";
import Video from "./Video";
import isDev from "../utils/isDev";

function Ending(props: ScreenProps) {
  const endingSelected = useAtomValue(endingAtom);
  const [endingStep, setEndingStep] = useState(0);

  useEffect(() => {
    if (!isDev()) {
        const myEvent = new CustomEvent("game_end", {
            detail: {},
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        document.dispatchEvent(myEvent);
    }
}, []);

  return (
    <>
      {endingSelected === 0 ?
        <>
          {endingStep === 0 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-best_txt01"].chara}
          />}
          {endingStep === 1 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-best_txt02"].chara}
          />}
          {endingStep === 2 && <InfoPage
            continue={() => props.onNext(TitleScreen)}
            text={endingText["ending-best_txt03"].chara}
          />}
          {endingStep === 3 && <Video
            videoEnded={() => props.onNext(TitleScreen)}
            videoSrc={require(`../assets/videos/endings/Finale best.mp4`)}
          />}
        </>
        : null
      }
      {endingSelected === 1 ?
        <>
          {endingStep === 0 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-good_txt01"].chara}
          />}
          {endingStep === 1 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-good_txt02"].chara}
          />}
          {endingStep === 2 && <InfoPage
            continue={() => props.onNext(TitleScreen)}
            text={endingText["ending-good_txt03"].chara}
          />}
          {endingStep === 3 && <Video
            videoEnded={() => props.onNext(TitleScreen)}
            videoSrc={require(`../assets/videos/endings/Finale good.mp4`)}
          />}
        </>
        : null
      }
      {endingSelected === 2 ?
        <>
          {endingStep === 0 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-bad_txt01"].chara}
          />}
          {endingStep === 1 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-bad_txt02"].chara}
          />}
          {endingStep === 2 && <InfoPage
            continue={() => props.onNext(TitleScreen)}
            text={endingText["ending-bad_txt03"].chara}
          />}
          {endingStep === 3 && <Video
            videoEnded={() => props.onNext(TitleScreen)}
            videoSrc={require(`../assets/videos/endings/Finale bad.mp4`)}
          />}
        </>
        : null
      }
      {endingSelected === 3 ?
        <>
          {endingStep === 0 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-worst_txt01"].chara}
          />}
          {endingStep === 1 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-worst_txt02"].chara}
          />}
          {endingStep === 2 && <InfoPage
            continue={() => setEndingStep(endingStep + 1)}
            text={endingText["ending-worst_txt03"].chara}
          />}
          {endingStep === 3 && <InfoPage
            continue={() => props.onNext(TitleScreen)}
            text={endingText["ending-worst_txt04"].chara}
          />}
            {endingStep === 4 && <Video
            videoEnded={() => props.onNext(TitleScreen)}
            videoSrc={require(`../assets/videos/endings/Finale worst.mp4`)}
          />}
        </>
        : null
      }
    </>
  )
};

export const EndingScreen: ScreenElement = {
  fn: Ending
}
