import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { GroupChoiceScreen } from "./GroupChoice";


function VisGroup2(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO, ALESSANDRO, VIS"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(GroupChoiceScreen)}
        />
    )
};

export const VisGroup2Screen: ScreenElement = {
    fn: VisGroup2
}


const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-04_txt01"].text,
        sender: chapter3Text["cap03-04_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-04_txt02"].text,
        sender: chapter3Text["cap03-04_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-04_txt03"].text,
        sender: chapter3Text["cap03-04_txt03"].chara
    },
    {
        txt: chapter3Text["cap03-04_txt04"].text,
        sender: chapter3Text["cap03-04_txt04"].chara
    },
    {
        txt: chapter3Text["cap03-04_txt05"].text,
        sender: chapter3Text["cap03-04_txt05"].chara
    },
    {
        txt: chapter3Text["cap03-04_txt06"].text,
        sender: chapter3Text["cap03-04_txt06"].chara
    },
]