import React from "react"
import Video from "../../components/Video";
import { NoiseChoiceScreen } from "./NoiseChoice";

function NoiseVideo(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(NoiseChoiceScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_rumore.mp4`)}
        />
    )
};

export const NoiseVideoScreen: ScreenElement = {
    fn: NoiseVideo
}
