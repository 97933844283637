import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { ReliefInfoScreen } from "./ReliefInfo";

function BurningInfo(props:ScreenProps){
  return (
    <InfoPage
    continue={() => props.onNext(ReliefInfoScreen)}
    text={chapter1Text["cap01-04-option-01_txt02"].text}
    />
  )
};

export const BurningInfoScreen: ScreenElement = {
fn: BurningInfo
}
