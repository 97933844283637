import { useSetAtom } from "jotai";
import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { leaveEnvelopeAtom } from "../../atoms/choicesAtom";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { GroupChatInfoScreen } from "../chapter 1/GroupChatInfo";
import { LeaveEnvelopeChatScreen } from "./LeaveEnvelopeChat";
import { OpenEnvelopeGroup1Screen } from "./OpenEnvelopeGroup1";

export function EnvelopeChoice(props: ScreenProps) {
  const setLeaveEnvelope = useSetAtom(leaveEnvelopeAtom)
  const setPoints = useSetAtom(handlePointsAtom);
  const choiceA: string = chapter4Text["cap04-02_scelta_option-01"].text;
  const choiceB: string = chapter4Text["cap04-02_scelta_option-02"].text;

  function handleChoiceSelected(choice: string) {

    if (choice === choiceA) {
      setPoints(parseInt(chapter4Text["cap04-02_scelta_option-01"].value));
      setLeaveEnvelope(true);
      props.onNext(LeaveEnvelopeChatScreen)
    } else {
      setPoints(parseInt(chapter4Text["cap04-02_scelta_option-02"].value));
      setLeaveEnvelope(false);
      props.onNext(OpenEnvelopeGroup1Screen)
    }
  }

  return (
    <VideoChoice
      videoSrc={require(`../../assets/videos/chapter4/capitolo_4_Antonio_03.mp4`)}
      choiceDelay={3}
      question={chapter4Text["cap04-02-scelta_txt"].text}
      choiceSelected={handleChoiceSelected}
      mirrored={true}
      choice={{
        choiceA,
        choiceB,
      }}
    />
  );
}

export const EnvelopeChoiceScreen: ScreenElement = {
  fn: EnvelopeChoice,
};

