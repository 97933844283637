import React, { useEffect } from 'react'
import logo from '../assets/images/layout/logo.png';
import scratch from '../assets/images/layout/scratch.png';
import blackMan from '../assets/images/layout/black-man.png';
import ButtonCustom from './ButtonCustom';
import { useSpring, animated } from 'react-spring';
import SettingsFragment from '../fragments/SettingsFragment';
import { useAtom, useSetAtom } from 'jotai';
import { settingsVisibleAtom } from '../atoms/settingsAtom';
import { ChapterSelectionScreen } from './ChapterSelection';
import { completedChaptersAtom } from '../atoms/chaptersAtom';
import { storageChaptersKey } from '../utils/storageUtils';

export function TitlePage(props:ScreenProps) {
  const [completedChapters, setCompletedChapters] = useAtom(completedChaptersAtom)
  const setSettingsOn = useSetAtom(settingsVisibleAtom);
  const buttonIn = useSpring({to:{transform:'translateX(0)', opacity:1}, from:{transform:'translateX(100px)', opacity:0}, delay:200});  
  const buttonIn2 = useSpring({to:{transform:'translateX(0)', opacity:1}, from:{transform:'translateX(-100px)', opacity:0}, delay:200});  
  const manIn = useSpring({to:{transform:'translateY(0)', opacity:1}, from:{transform:'translateY(100px)', opacity:0}, delay:200});
  const logoIn = useSpring({to:{transform:'scale(1)'}, from:{transform:'scale(5)'}});

  useEffect(() => {
   const savedChapters = localStorage.getItem(storageChaptersKey);
    if(savedChapters){
      setCompletedChapters(JSON.parse(savedChapters));
    }
  }, [])

  return (
    <React.Fragment>
      <div className="game-box inner p-4 intro">
        <div className="bg-content">
          <div className="stripe">
            <animated.img style={logoIn} className="logo" src={logo} alt="logo gameon" />
            <SettingsFragment click={() => setSettingsOn(true)} />
          </div>
        </div>
        <div className="wrapper-buttons">
          <animated.div style={buttonIn}>
            <ButtonCustom label='Nuova partita' click={() => {
              setCompletedChapters([])
              props.onNext(ChapterSelectionScreen)}} />
          </animated.div>
          {completedChapters.length > 0 && <animated.div style={buttonIn2}>
            <ButtonCustom label='Continua' click={() => props.onNext(ChapterSelectionScreen)} />
          </animated.div> }
        </div>
        <animated.img style={manIn} className="black-man" src={blackMan} alt="black man" />
        <img className="bg-scratch" src={scratch} alt="" />
      </div>
    </React.Fragment>
  )
}

export const TitleScreen:ScreenElement ={
  fn: TitlePage, 
} 