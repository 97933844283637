import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { VisGroup2Screen } from "./VisGroup2";

function SmallGroupChat2(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO, ALESSANDRO"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(VisGroup2Screen)}
        />
    )
};

export const SmallGroupChat2Screen: ScreenElement = {
    fn: SmallGroupChat2
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-03_txt02"].text,
        sender: chapter3Text["cap03-03_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-03_txt03"].text,
        sender: chapter3Text["cap03-03_txt03"].chara
    },
]