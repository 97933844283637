import { useSetAtom } from "jotai";
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import VideoChoice from "../../components/VideoChoice";
import {handlePointsAtom} from "../../atoms/pointsAtom";
import { PictureChatScreen } from "./PictureChat";
import { DisegnoPersoVideoScreen } from "./DisegnoPersoVideo";
import { tellAtom } from "../../atoms/choicesAtom";

export function IntroVideo(props: ScreenProps) {
    const setTold = useSetAtom(tellAtom)
    const setPoints = useSetAtom(handlePointsAtom);
    const choiceA: string = chapter1Text["cap01-01_scelta_option-01"].text;
    const choiceB: string = chapter1Text["cap01-01_scelta_option-02"].text;

    function handleChoiceSelected(choice: string) {
        if(choice === choiceA) {
            setPoints(parseInt(chapter1Text["cap01-01_scelta_option-01"].value));
            setTold(true);
            props.onNext(DisegnoPersoVideoScreen);
        }else{
            setPoints(parseInt(chapter1Text["cap01-01_scelta_option-02"].value));
            setTold(false);
            props.onNext(PictureChatScreen);
        }
    }

    return (
        <VideoChoice
            videoSrc={require(`../../assets/videos/chapter1/CAPITOLO_01_01.mp4`)}
            choiceDelay={14}
            question={chapter1Text["cap01-01-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
    );
}

export const introVideoScreen: ScreenElement = {
    fn: IntroVideo,
};
