import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { GroupWhileGoingScreen } from "./GroupWhileGoing";


function GroupWhileGoingInfo(props:ScreenProps){
    
  return (
    <InfoPage
        continue={() => props.onNext(GroupWhileGoingScreen)}
        text={chapter3Text["cap03-07_txt01"].text}
      />
  )
};

export const GroupWhileGoingInfoScreen: ScreenElement = {
fn: GroupWhileGoingInfo
}
