import React from "react";
import InfoPage from "../../components/InfoPage";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { MessagesChoiceScreen } from "./MessagesChoice";
import { GroupChatScreen } from "./GroupChat";

export function GroupChatInfo(props: ScreenProps) {
    return (
      <InfoPage
        continue={() => props.onNext(GroupChatScreen)}
        text={chapter2Text["cap02-04_txt02"].text}
      />
    );
}

export const GroupChatInfoScreen:ScreenElement ={
 fn: GroupChatInfo,
};
