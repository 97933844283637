import React from "react";
import InfoPage from "../../components/InfoPage";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { VisGroupDoneScreen } from "./VisGroupDone";

export function AllMessagesInfo(props: ScreenProps) {
  return (
    <InfoPage
      continue={() => props.onNext(VisGroupDoneScreen)}
      text={chapter2Text["cap02-05_txt01"].text}
    />
  );
}

export const AllMessagesInfoScreen: ScreenElement = {
  fn: AllMessagesInfo,
};
