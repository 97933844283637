import React from "react"
import VideoGroupChat from "../../components/VideoGroupChat"
import chapter52Text from "../../assets/texts/Capitolo 5-2.json";
import { messageType } from "../../components/Chat"
import { Option01EndingScreen } from "./Option01Ending";

function Chapter52Chat(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter5/chatTemp.mp4"
            sender={"ANTONIO, YOUSSEF, VIS"}
            receiver={"ALESSANDRO"}
            messages={messages}
            phoneDelay={0}
            canContinue={() => props.onNext(Option01EndingScreen)}
        />
    )
};

export const Chapter52ChatScreen: ScreenElement = {
    fn: Chapter52Chat
}

const messages: messageType[] = [
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt02"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt02"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt03"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt03"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt04"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt04"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt05"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt05"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt06"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt06"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt07"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt07"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt08"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt08"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt09"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt09"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt10"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt10"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt11"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt11"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt12"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt12"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt13"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt13"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt14"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt14"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt15"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt15"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt16"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt16"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt17"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt17"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt18"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt18"].chara
    },
    {
        txt: chapter52Text["cap052-01-option-04-02-01-02_txt19"].text,
        sender: chapter52Text["cap052-01-option-04-02-01-02_txt19"].chara
    },
]