import React from "react"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import Video from "../../components/Video"
import { PictureChatScreen } from "./PictureChat";

function DisegnoPersoVideo(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(PictureChatScreen)}
            videoSrc={require(`../../assets/videos/chapter1/${"CAPITOLO_01_02.mp4"}`)}
        />
    )
};

export const DisegnoPersoVideoScreen: ScreenElement = {
    fn: DisegnoPersoVideo
}
