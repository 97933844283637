import React, { useEffect } from "react"
import InfoPage from "../../components/InfoPage"
import chapter52Text from "../../assets/texts/Capitolo 5-2.json";
import { Chapter52ChatScreen } from "./Chapter52Chat";
import isDev from "../../utils/isDev";

function ChapterIntro01(props:ScreenProps){
  useEffect(() => {
    if (!isDev()) {
        const myEvent = new CustomEvent("chapter_5", {
            detail: {},
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        document.dispatchEvent(myEvent);
    }
}, []);

  return (
    <InfoPage
        continue={() => props.onNext(Chapter52ChatScreen)}
        text={chapter52Text["cap052-01-option-04-02-01-02_txt01"].text}
    />
  )
};

export const ChapterIntro01Screen: ScreenElement = {
fn: ChapterIntro01
}
