import React from 'react'
import logo from '../assets/images/layout/logo.png';
import scratch from '../assets/images/layout/scratch.png';
import blackMan from '../assets/images/layout/black-man.png';
import { useSpring, animated } from 'react-spring';
import { useAtomValue } from 'jotai';
import { chapter51ComingUpAtom, completedChaptersAtom } from '../atoms/chaptersAtom';
import {leaveEnvelopeAtom, listenToSabriAtom} from '../atoms/choicesAtom';
import ChapterButton from '../fragments/ChapterButton';
import { StartingInfoScreen } from '../chapters/chapter 1/StartingInfo';
import { Chapter2IntroScreen } from '../chapters/chapter 2/Chapter2Intro';
import { Chapter4IntroChatScreen } from '../chapters/chapter 4/Chapter4IntroChat';
import { Chapter51IntroScreen } from '../chapters/chapter 5-1/Chapter51Intro';
import { Chapter3IntroScreen } from '../chapters/chapter 3/Chapter3Intro';
import { ChapterIntro01Screen } from '../chapters/chapter 5-2/ChapterIntro01';
import { ChapterIntro02Screen } from '../chapters/chapter 5-2/ChapterIntro02';

export default function ChapterSelection(props: ScreenProps) {
  const evenloperLeaved = useAtomValue(leaveEnvelopeAtom);
  const chapter51 = useAtomValue(chapter51ComingUpAtom);
  const completedChapters = useAtomValue(completedChaptersAtom);
  const buttonIn = useSpring({ to: { transform: 'translateX(0)', opacity: 1 }, from: { transform: 'translateX(100px)', opacity: 0 } });
  const manIn = useSpring({ to: { transform: 'translateY(0)', opacity: 1 }, from: { transform: 'translateY(100px)', opacity: 0 }, delay: 200 });
  const logoIn = useSpring({ to: { transform: 'scale(1)' }, from: { transform: 'scale(5)' } });

  function selectionHandler(chapter: number, chapter5: boolean) {
    switch (chapter) {
      case 1:
        props.onNext(Chapter2IntroScreen);
        break;
        case 2:
          props.onNext(Chapter3IntroScreen);
          break;
      case 3:
        props.onNext(Chapter4IntroChatScreen);
        break;
        case 4 :
          if(chapter5){
            props.onNext(Chapter51IntroScreen);
          }else{
            if(evenloperLeaved){
              props.onNext(ChapterIntro01Screen);
            }else{
              props.onNext(ChapterIntro02Screen);

            }
          }
        break;
      default:
        props.onNext(StartingInfoScreen);
    }
      
  }

  return (
    <React.Fragment>
      <div className="game-box inner p-4 chapters">
        <div className="bg-content">
          <animated.img style={logoIn} className="logo" src={logo} alt="logo gameon" />
          <div className="wrapper-buttons d-flex flex-column align-items-end">
            {[...new Array(5)].map((_, index) =>
              <animated.div style={buttonIn}>
                <ChapterButton
                  key={'chapterBtn' + index}
                  label={'Capitolo ' + (index + 1)}
                  unlocked={index <= completedChapters.length}
                  new={index === completedChapters.length}
                  click={() => selectionHandler(index, chapter51)}
                />
              </animated.div>
            )}
          </div>
        </div>
      </div>
      <animated.img style={manIn} className="black-man" src={blackMan} alt="black man" />
      <img className="bg-scratch" src={scratch} alt="" />
    </React.Fragment>
  )
}

export const ChapterSelectionScreen: ScreenElement = {
  fn: ChapterSelection
}
