export type RowAvatar = {
    nameAvatar: string,
    label: string,
    classes?: string,
    imgSrc: string,
}

export default function RowAvatar(props: RowAvatar) {

    return (
        <div className={`wrapper-row-avatar ${props.classes ?? ''}`}>
            <div className="content-row">
                <img className="avatar" src={props.imgSrc} alt="avatar" />
                <div>
                    <h3 className="text-white mb-2">
                        {props.nameAvatar}
                    </h3>
                    <p>
                        {props.label}
                    </p>
                </div>
            </div>
        </div>
    )
}
