import React from "react"
import { EndingScreen } from "../../components/Ending"
import Video from "../../components/Video"
import { VisAskChoiceScreen } from "./VisAskChoice"

function ContinueEnding(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(EndingScreen)}
            videoSrc={require(`../../assets/videos/chapter5/cap052-04-02_video02.mp4`)}
        />
    )
};

export const ContinueEndingScreen: ScreenElement = {
    fn: ContinueEnding
}
