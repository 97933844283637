import React from 'react'
import Characters from '../assets/texts/Chara.json';
import RowAvatar from './RowAvatar';

require("../assets/images/layout/ALE_1.jpg");
require("../assets/images/layout/YUS_2.jpg");
require("../assets/images/layout/ANTO_4.jpg");
require("../assets/images/layout/GIOVANNI_1.png");
require("../assets/images/layout/SABRI_1.png");
require("../assets/images/layout/GIUSY_1.png");
require("../assets/images/layout/VIS_1.png");


export default function CharaSection() {

  return (
    <>
      {Characters.info.map(chara =>
        <RowAvatar
          key={chara.name}
          imgSrc={require(`../assets/images/layout/${chara.image}`)}
          nameAvatar={chara.name}
          label={chara.story} />
      )}
    </>
  )
}
