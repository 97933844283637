import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { LastChatScreen } from "./LastChat";


function GroupChatPicInfo(props: ScreenProps) {
    return (
        <InfoPage
        continue={() => props.onNext(LastChatScreen)}
        text={chapter1Text["cap01-06_txt02"].text}
        />
    )
};

export const GroupChatPicInfoScreen: ScreenElement = {
    fn: GroupChatPicInfo
}
