import React from "react"
import Video from "../../components/Video";
import { CopVideo1Screen } from "./CopVideo1";

function NervousSmoke(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(CopVideo1Screen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_motoPaglia.mp4`)}
        />
    )
};

export const NervousSmokeScreen: ScreenElement = {
    fn: NervousSmoke
}
