import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring';
import ButtonCustom from '../components/ButtonCustom';
import { inFromLeft, outToLeft } from '../utils/springAnimations';


type Props = {
    continueHandler: () => void,
    messages: number,
    delay: number,
    mirrored?: boolean,
}

export default function ContinueButton(props: Props) {
    const [canContinue, setCanContinue] = useState<boolean>(false);
    const [timePassed, setTimePassed] = useState<number>(0);

    useEffect(() => {
        let timeToWait = props.delay + (props.messages * 3);

        console.log(timeToWait);

        if (timePassed >= timeToWait) {
            setCanContinue(true);
        } else {
            setTimeout(() => {
                setTimePassed(timePassed + 1);
            }, 1000)
        }
    }, [timePassed, props.messages])

    useEffect(() => {
        console.log('messages', props.messages)
        setCanContinue(false);
    }, [props.messages])

    const continueIn = useSpring({
        ...(canContinue ? inFromLeft : outToLeft),
    });

    function handleContinue() {
        setCanContinue(false);
        props.continueHandler();
    }

    return (
        canContinue ?
            <div className={`wrapper-buttons ${props.mirrored ? 'mirror' : ''}`}>
                <animated.div style={continueIn}>
                    <ButtonCustom label={"Continua"} click={handleContinue} />
                </animated.div>
            </div>
            : null
    )
}
