import React, { useEffect } from "react"
import InfoPage from "../../components/InfoPage"
import isDev from "../../utils/isDev";
import { StartVideoScreen } from "./StartVideo"

function StartingInfo(props: ScreenProps) {

    useEffect(() => {
        if (!isDev()) {
            const myEvent = new CustomEvent("chapter_1", {
                detail: {},
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            document.dispatchEvent(myEvent);
        }
    }, []);

    return (
        <InfoPage
            continue={() => props.onNext(StartVideoScreen)}
            title={"Questa è un’avventura grafica a bivi."}
            text={"Nel corso della storia ti verranno presentate delle scelte da compiere e da queste dipende il corso degli eventi."}
            underline={"Ognuna di queste scelte avrà un suo peso."} />
    )
};

export const StartingInfoScreen: ScreenElement = {
    fn: StartingInfo
}
