import React from "react"
import { messageType } from "../../components/Chat"
import VideoChat from "../../components/VideoChat"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { TitleScreen } from "../../components/TitlePage";
import { useAtom } from "jotai";
import { completedChaptersAtom } from "../../atoms/chaptersAtom";
import { ChapterSelectionScreen } from "../../components/ChapterSelection";


function LastChat(props: ScreenProps) {
  const [completedChapters, setCompletedChapters] = useAtom(completedChaptersAtom);

  return (
    <VideoChat
      videoSrc="chapter1/CAPITOLO_01_10.mp4"
      sender={"ignoto"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={23}
      mirrored={true}
      canContinue={() => {
        if(!completedChapters.includes(0)){
          setCompletedChapters([...completedChapters, 0]);
          localStorage.setItem("completedChapters", JSON.stringify([...completedChapters, 0]));
        }
        props.onNext(ChapterSelectionScreen);
      }}
    />
  )
};

export const LastChatScreen: ScreenElement = {
  fn: LastChat
}


const messages: messageType[] = [
  {
    txt: "pic3.png",
    sender: chapter1Text["cap01-06_txt03"].chara,
    type: "img",
  },
  {
    txt: chapter1Text['cap01-06_txt03'].text,
    sender: chapter1Text["cap01-06_txt03"].chara
  },
  {
    txt: chapter1Text['cap01-06_txt04'].text,
    sender: chapter1Text["cap01-06_txt04"].chara
  },
]