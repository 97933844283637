import React from "react"
import Video from "../../components/Video"
import { WaitMultiNarrationScreen } from "./WaitMultiNarration"

function NoiseWait(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(WaitMultiNarrationScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_aiutano.mp4`)}
        />
    )
};

export const NoiseWaitScreen: ScreenElement = {
    fn: NoiseWait
}
