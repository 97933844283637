import { useAtomValue } from "jotai";
import React from "react"
import { liedToVisBoxAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import { VisLastChanceChoiceMultipleStartScreen } from "./VisLastChanceChoiceMultipleStart";

function VisAfterChoice(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(VisLastChanceChoiceMultipleStartScreen)}
            videoSrc={require(`../../assets/videos/chapter5/cap05-03_video_no scelta.mp4`)}
        />
    )
};

export const VisAfterChoiceScreen: ScreenElement = {
    fn: VisAfterChoice
}
