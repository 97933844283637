import React from "react"
import Video from "../../components/Video"
import { StopChatScreen } from "./StopChat"

function StopV2(props:ScreenProps){
  return (
    <Video
        videoEnded={() => props.onNext(StopChatScreen)}
        videoSrc={require(`../../assets/videos/chapter5/cap051-04-01_video02.mp4`)}
    />
  )
};

export const StopV2Screen: ScreenElement = {
fn: StopV2
}
