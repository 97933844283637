import React from "react"
import Video from "../../components/Video";
import { CopInfoScreen } from "./CopInfo";

function CopVideo1(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(CopInfoScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_sbirro.mp4`)}
        />
    )
};

export const CopVideo1Screen: ScreenElement = {
    fn: CopVideo1
}
