import React from "react"
import chapter51Text from "../../assets/texts/Capitolo 5-1.json";
import InfoPage from "../../components/InfoPage";
import { VisOutsideScreen } from "./VisOutside";

function VisMessageInfo(props: ScreenProps) {
    return (
        <InfoPage
            continue={() => props.onNext(VisOutsideScreen)}
            text={chapter51Text["cap05-04_txt01"].text}
        />
    )
};

export const VisMessageInfoScreen: ScreenElement = {
    fn: VisMessageInfo
}
