import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import InfoPage from "../../components/InfoPage";
import { GunSabriChatScreen } from "./GunSabriChat";

export function PreGunInfo(props: ScreenProps) {
  return (
    <InfoPage
      continue={() => props.onNext(GunSabriChatScreen)}
      text={chapter4Text["cap04-02-option-01_txt13"].text}
    />
  );
}

export const PreGunInfoScreen: ScreenElement = {
  fn: PreGunInfo,
};
