import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import InfoPage from "../../components/InfoPage";
import { GroupEnvelopeChatScreen } from "./GroupEnvelopeChat";

export function EnvelopeInfo(props: ScreenProps) {
  return (
    <InfoPage
      continue={() => props.onNext(GroupEnvelopeChatScreen)}
      text={chapter4Text["cap04-02_txt01"].text}
    />
  );
}

export const EnvelopeInfoScreen: ScreenElement = {
  fn: EnvelopeInfo,
};
