import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { PictureChatScreen } from "./PictureChat";
import { BullyChatScreen } from "./BullyChat";
import { PicFoundChatScreen } from "./PicFoundChat";

function PicFoundChatIntro(props: ScreenProps) {
    return (
        <InfoPage
            continue={() => props.onNext(PicFoundChatScreen)}
            text={chapter1Text["cap01-03_txt01"].text}
            />
    )
};

export const PicFoundChatIntroScreen: ScreenElement = {
    fn: PicFoundChatIntro
}
