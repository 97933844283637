import { useSetAtom } from "jotai";
import React from "react"
import chapter51Text from "../../assets/texts/Capitolo 5-1.json";
import { liedToVisBoxAtom } from "../../atoms/choicesAtom";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { VisBoxOutcomeScreen } from "./VisBoxOutcome";

function VisBox(props: ScreenProps) {
    const setVisBoxOutcome = useSetAtom(liedToVisBoxAtom);
    const setPoints = useSetAtom(handlePointsAtom);
    const choiceA: string = chapter51Text["cap05-02_scelta_option-01"].text;
    const choiceB: string = chapter51Text["cap05-02_scelta_option-02"].text;

    function handleChoiceSelected(choice: string) {

        if (choice === choiceA) {
            setPoints(parseInt(chapter51Text["cap05-02_scelta_option-01"].value));
            setVisBoxOutcome(true);
            props.onNext(VisBoxOutcomeScreen);
        } else {
            setPoints(parseInt(chapter51Text["cap05-02_scelta_option-02"].value));
            setVisBoxOutcome(false);
            props.onNext(VisBoxOutcomeScreen);
        }
    }

    return (
        <VideoChoice
            videoSrc={require(`../../assets/videos/chapter5/cap05-02.mp4`)}
            choiceDelay={30}
            question={chapter51Text["cap05-02-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
    )
};

export const VisBoxScreen: ScreenElement = {
    fn: VisBox
}
