import React from "react"
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { GiovanniImpact1Screen } from "./GiovanniImpact1";


function Chapter3Chat2(props: ScreenProps) {
    return (
        <VideoChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ALESSANDRO"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(GiovanniImpact1Screen)}
        />
    )
};

export const Chapter3Chat2Screen: ScreenElement = {
    fn: Chapter3Chat2
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-01_txt07"].text,
        sender: chapter3Text["cap03-01_txt07"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt08"].text,
        sender: chapter3Text["cap03-01_txt08"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt09"].text,
        sender: chapter3Text["cap03-01_txt09"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt10"].text,
        sender: chapter3Text["cap03-01_txt10"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt11"].text,
        sender: chapter3Text["cap03-01_txt11"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt12"].text,
        sender: chapter3Text["cap03-01_txt12"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt13"].text,
        sender: chapter3Text["cap03-01_txt13"].chara
    },
]
