import React from 'react';
import iconLock from './../assets/images/icons/lock.png';


export default function ButtonLocked() {

  return (
    <a href='#' className="button locked">
      <img className='mr-2' src={iconLock} alt="lock" />
      <span>bl0cc4t0</span>
    </a>
  )
}
