import React, { useState } from "react"
import InfoPage from "../../components/InfoPage";
import chapter52Text from "../../assets/texts/Capitolo 5-2.json";
import { VisAskChoiceScreen } from "./VisAskChoice";

function Option01Ending(props: ScreenProps) {
    const [step, setStep] = useState(0);

    return (
        <>
        {step === 0 &&
            <InfoPage
                continue={() => setStep(1)}
                text={chapter52Text["cap052-01-option-04-02-01-02_txt20"].text}
            />
        }
        {step === 1 &&
            <InfoPage
                continue={() => setStep(2)}
                text={chapter52Text["cap052-01-option-04-02-01-02_txt21"].text}
            />}

        {step === 2 &&
            <InfoPage
                continue={() => props.onNext(VisAskChoiceScreen)}
                text={chapter52Text["cap052-01-option-04-02-01-02_txt22"].text}
            />
        }
    </>
    )
};

export const Option01EndingScreen: ScreenElement = {
    fn: Option01Ending
}
