import React from "react"
import Video from "../../components/Video"
import { ContinueEndingScreen } from "./ContinueEnding"

function ContinueV1(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(ContinueEndingScreen)}
            videoSrc={require(`../../assets/videos/chapter5/cap051-04-02_video01.mp4`)}
        />
    )
};

export const ContinueV1Screen: ScreenElement = {
    fn: ContinueV1
}
