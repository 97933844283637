import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import { NervousChoiceScreen } from "./NervousChoice";

function NervousChat(props: ScreenProps) {
    return (
        <VideoChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(NervousChoiceScreen)}
        />
    )
};

export const NervousChatScreen: ScreenElement = {
    fn: NervousChat
}

const messages: messageType[] = [
    {
        txt: chapter3Text['cap03-08_txt02'].text,
        sender: chapter3Text["cap03-08_txt02"].chara
    },
    {
        txt: chapter3Text['cap03-08_txt03'].text,
        sender: chapter3Text["cap03-08_txt03"].chara
    },
    {
        txt: chapter3Text['cap03-08_txt04'].text,
        sender: chapter3Text["cap03-08_txt04"].chara
    },
    {
        txt: chapter3Text['cap03-08_txt05'].text,
        sender: chapter3Text["cap03-08_txt05"].chara
    },
    {
        txt: chapter3Text['cap03-08_txt06'].text,
        sender: chapter3Text["cap03-08_txt06"].chara
    },
    {
        txt: chapter3Text['cap03-08_txt07'].text,
        sender: chapter3Text["cap03-08_txt07"].chara
    },
]