import { atom } from "jotai";

export const settingsVisibleAtom = atom<boolean>(false);

export const audioSettingAtom = atom<number>(10);
export const brightnessSettingAtom = atom<number>(10);


export const getAudioSettingAtom = atom(
  (get) => get(audioSettingAtom) / 10
);

export const getBrightnessSettingAtom = atom(
  (get) => get(brightnessSettingAtom) / 10
);