import React, { useState } from 'react';

export type ButtonProps = {
  label: string,
  classes?: string,
  labelTop?: string,
  style?: React.CSSProperties,
  click?: ()=> void
}

export default function ButtonCustom(props: ButtonProps) {

  return (
      <div className={"button " + (props.classes ?? '')} onClick={props.click} style={props.style}>
        <span className="text-bottom">{props.label}</span>
        <span className="text-top">{props.labelTop}</span>
      </div>
  )
}
