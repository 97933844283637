import React from "react"
import Video from "../../components/Video"
import { NervousInfoScreen } from "./NervousInfo"

function WarehouseImpatient(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(NervousInfoScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_attesa.mp4`)}
        />
    )
};

export const WarehouseImpatientScreen: ScreenElement = {
    fn: WarehouseImpatient
}
