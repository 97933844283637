import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react"
import chapter52Text from "../../assets/texts/Capitolo 5-2.json";
import { goOnWithRobberyAtom, leaveEnvelopeAtom, listenToSabriAtom } from "../../atoms/choicesAtom";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { VisMessageInfoScreen } from "./VisMessageInfo";


function VisLastChanceChoiceMultipleStart(props: ScreenProps) {
    const setRobbery = useSetAtom(goOnWithRobberyAtom)
    const setPoints = useSetAtom(handlePointsAtom);
    const leaveEnvelope = useAtomValue(leaveEnvelopeAtom);
    const sabriListened = useAtomValue(listenToSabriAtom);
    const choiceA: string = chapter52Text["cap052-04_scelta_option-01"].text;
    const choiceB: string = chapter52Text["cap052-04_scelta_option-02"].text;
    
    function handleChoiceSelected(choice: string) {

        if (choice === choiceA) {
            setPoints(parseInt(chapter52Text["cap052-04_scelta_option-01"].value));
            setRobbery(false);
        } else {
            setPoints(parseInt(chapter52Text["cap052-04_scelta_option-02"].value));
            setRobbery(true);
        }
        props.onNext(VisMessageInfoScreen);
    }

    return (
        <VideoChoice
            videoSrc={leaveEnvelope ? require(`../../assets/videos/chapter5/cap052-04-option-04-02-01-02_video01.mp4`) : require(`../../assets/videos/chapter5/cap052-04-option-04-02-02-02_video.mp4`)}
            choiceDelay={36}
            question={chapter52Text["cap052-04-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
    )
};

export const VisLastChanceChoiceMultipleStartScreen: ScreenElement = {
    fn: VisLastChanceChoiceMultipleStart
}

