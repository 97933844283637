import React from 'react'
import { ButtonProps } from '../components/ButtonCustom'
import ButtonLocked from '../components/ButtonLocked'

type props = ButtonProps & {
  unlocked?: boolean,
  completed?: boolean,
  new?: boolean,
}

export default function ChapterButton(props: props) {
  return (
    <React.Fragment>
      {props.unlocked ? 
        <div className={`button ${props.classes ?? ''} ${props.completed ? 'completed' : 'to-complete'}`} onClick={props.click}>
          <span className="text-bottom">{props.label}</span>
          <span className="text-top">{props.completed ? 'C0mplet0' : ''} {props.new ? 'New' : ''}</span>
        </div>
      : <ButtonLocked />}
    </React.Fragment>
  )
}
