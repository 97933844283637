import React from 'react'
import logo from '../assets/images/layout/logo.png';
import iconAudio from '../assets/images/icons/audio.png';
import iconLight from '../assets/images/icons/light.png';
import scratch from '../assets/images/layout/scratch.png';
import blackMan from '../assets/images/layout/black-man.png';
import { useSpring, animated } from 'react-spring';
import { useAtom, useSetAtom } from 'jotai';
import { audioSettingAtom, brightnessSettingAtom, settingsVisibleAtom } from '../atoms/settingsAtom';

export default function Settings() {
  const setSettingsOn = useSetAtom(settingsVisibleAtom);
  const [audio, setAudio] = useAtom(audioSettingAtom);
  const [brightness, setBrightness] = useAtom(brightnessSettingAtom);

  const manIn = useSpring({to:{left:'0rem'}, from:{left:'10rem'}});
  const logoIn = useSpring({to:{left:'-11rem'}, from:{left:'7rem'}});
  const fadeUp = useSpring({to:{transform:'translateY(0)', opacity:1}, from:{transform:'translateY(10%)', opacity:0}, delay:100});

  return (
    <React.Fragment>
           <div className="game-box p-4 settings inner">
        <div className="bg-content">
          <animated.img style={logoIn} className="logo" src={logo} alt="logo gameon" />
          <div className="stripe">
            <animated.div style={fadeUp} className="wrapper-btn-settings">
              <h4>settings</h4>
              <div className="icon-back" onClick={() => setSettingsOn(false)}></div>
            </animated.div>
          </div>
          <animated.div style={fadeUp} className="wrapper-range-position">
            <div className="range-slider d-flex align-items-center mb-2">
              <img className="mr-1" src={iconAudio} alt="audio" />
              <input className="range-slider__range" value={audio} onChange={(e) => setAudio(parseInt(e.target.value))} type="range" min="0" max="10" step={1}></input>
            </div>
            <div className="range-slider d-flex align-items-center">
              <img className="mr-1" src={iconLight} alt="light" />
              <input className="range-slider__range" type="range" value={brightness} onChange={(e) => setBrightness(parseInt(e.target.value))} min="0" max="10" step={1}></input>
            </div>
          </animated.div>

        </div>
        <animated.img style={manIn} className="black-man" src={blackMan} alt="black man" />
        <img className="bg-scratch" src={scratch} alt=""/>

      </div>

    </React.Fragment>
  )
}
