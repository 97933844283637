import React from "react"
import { messageType } from "../../components/Chat"
import VideoChat from "../../components/VideoChat"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import ChoiceOverlay from "../../components/ChoiceOverlay";
import { BullyChatNextIntroScreen } from "./BullyChatNextIntro";

function PicFoundChat(props: ScreenProps) {
    const [messagesToUse, setMessagesToUse] = React.useState<messageType[]>([]);
    const [chatStep, setChatStep] = React.useState<number>(0);

    function handleChoice(choice: string) {
        console.log("choice: " + choice);
        if (choice === chapter1Text['cap01-03_scelta_option-01'].text) {
            setMessagesToUse(messagesA);
        } else {
            setMessagesToUse(messagesB);
        }

        setChatStep(2);
    }

    function handleContinue(currentStep: number) {
        if (currentStep === 2) {
            props.onNext(BullyChatNextIntroScreen);
        } else {
            setChatStep(currentStep + 1);
        }
    }

    return (
        <>
            <VideoChat
                videoSrc="chapter1/CAPITOLO_01_07.mp4"
                sender={"giusy"}
                receiver={"ANTONIO"}
                messages={messages}
                additionalMessages={messagesToUse}
                phoneDelay={23}
                mirrored={true}
                canContinue={() => handleContinue(chatStep)}
            />

            {chatStep === 1 ?
                <ChoiceOverlay
                    portrait={""}
                    question={chapter1Text['cap01-03-scelta_txt'].text}
                    choiceA={chapter1Text['cap01-03_scelta_option-01'].text}
                    choiceB={chapter1Text['cap01-03_scelta_option-02'].text}
                    choiceDone={handleChoice}
                />
                : null}
        </>
    )
};

export const PicFoundChatScreen: ScreenElement = {
    fn: PicFoundChat
}


const messages: messageType[] = [
    {
        txt: chapter1Text['cap01-03_txt02'].text,
        sender: chapter1Text["cap01-03_txt02"].chara
    },
    {
        txt: "pic2.png",
        sender: chapter1Text["cap01-03_txt02"].chara,
        type: "img",
    },
    {
        txt: chapter1Text['cap01-03_txt03'].text,
        sender: chapter1Text["cap01-03_txt03"].chara
    },
    {
        txt: chapter1Text['cap01-03_txt04'].text,
        sender: chapter1Text["cap01-03_txt04"].chara
    },

]

const messagesA: messageType[] = [
    {
        txt: chapter1Text['cap01-03-option-01_txt01'].text,
        sender: chapter1Text["cap01-03-option-01_txt01"].chara
    },
    {
        txt: chapter1Text['cap01-03-option-01_txt02'].text,
        sender: chapter1Text["cap01-03-option-01_txt02"].chara
    },
]

const messagesB: messageType[] = [
    {
        txt: chapter1Text['cap01-03-option-02_txt01'].text,
        sender: chapter1Text["cap01-03-option-02_txt01"].chara
    },
    {
        txt: chapter1Text['cap01-03-option-02_txt02'].text,
        sender: chapter1Text["cap01-03-option-02_txt02"].chara
    },
]