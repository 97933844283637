import React from "react"
import { messageType } from "../../components/Chat"
import VideoChat  from "../../components/VideoChat"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { BullyChatIntroScreen } from "./BullyChatIntro";
import { type } from "@testing-library/user-event/dist/type";


function PictureChat(props: ScreenProps) {

  return (
    <VideoChat
      videoSrc="chapter1/CAPITOLO_01_05.mp4"
      sender={"giusy"}
      receiver={"ANTONIO"}
      messages={messages} 
      phoneDelay={14}
      canContinue={() => props.onNext(BullyChatIntroScreen)}
    />
  )
};

export const PictureChatScreen: ScreenElement = {
  fn: PictureChat
}


const messages: messageType[] = [
  {
    txt: chapter1Text['cap01-01_txt02'].text,
    sender: chapter1Text["cap01-01_txt02"].chara
  },
  {
    txt: "pic1.png",
    sender: chapter1Text["cap01-01_txt02"].chara,
    type: "img",
  },
  {
    txt: chapter1Text["cap01-01_txt03"].text,
    sender: chapter1Text["cap01-01_txt03"].chara
  },
  {
    txt: chapter1Text["cap01-01_txt04"].text,
    sender: chapter1Text["cap01-01_txt04"].chara
  },
  {
    txt: chapter1Text["cap01-01_txt05"].text,
    sender: chapter1Text["cap01-01_txt05"].chara
  },
]
