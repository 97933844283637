import React, { useEffect, useRef, useState } from 'react';
import Balloon from './Balloon';
import ButtonCustom from './ButtonCustom';
import { useSpring, animated } from 'react-spring';
import { inFromLeft, outToLeft } from '../utils/springAnimations';
import ReactPlayer from 'react-player/lazy';
import Phone from './Phone';
import ContinueButton from '../fragments/ContinueButton';
import { messageType } from './Chat';
import { useAtomValue } from 'jotai';
import { getAudioSettingAtom } from '../atoms/settingsAtom';
import isDev from '../utils/isDev';

export type messageGroupType = messageType[];

type Props = {
    receiver: string,
    sender: string,
    messages: messageType[],
    phoneDelay: number,
    choice?: {
        choiceA: string,
        choiceB: string,
    },
    videoSrc: string,
    additionalMessages?: messageType[],
    mirrored?: boolean,
    canContinue: () => void,
};

export default function VideoChat(props: Props) {
    const volume = useAtomValue(getAudioSettingAtom);
    const [videoPlayed, setVideoPlayed] = useState<number>(0);
    const [choiceAnswer, setChoiceAnswer] = useState<string | undefined>(undefined);
    const [phoneOut, setPhoneOut] = useState<boolean>(false);

    //reset all state when new messages are received
    useEffect(() => {
        setVideoPlayed(0);
        setChoiceAnswer(undefined);
    }, [props.messages]);

    const choiceAIn = useSpring({
        to: { transform: !choiceAnswer ? 'translateX(0px)' : 'translateX(1000px)', opacity: !choiceAnswer ? 1 : 0 },
        from: { transform: !choiceAnswer ? 'translateX(1000px)' : 'translateX(0px)', opacity: !choiceAnswer ? 0 : 1 },
        delay: !choiceAnswer ? ((props.messages.length - 1) * 3000) : 0
    });

    const choiceBIn = useSpring({
        ...(choiceAnswer ? outToLeft : inFromLeft),
        delay: choiceAnswer ? 0 : ((props.messages.length - 1) * 3000)
    });

    useEffect(() => {
        if (videoPlayed + 1 >= props.phoneDelay) {
            setPhoneOut(true);
        }
    }, [videoPlayed, props.phoneDelay]);

    function choiceHandler(choice: string) {
        setChoiceAnswer(choice);
    }

    function continueHandler() {
        props.canContinue();
    }

    return (
        <React.Fragment>
            <div className="game-box inner p-4 chat">
                <div className="bg-content no-background">
                    <ContinueButton
                        continueHandler={continueHandler}
                        messages={props.messages.length + (props.additionalMessages ? props.additionalMessages.length : 0)}
                        delay={props.phoneDelay}
                        mirrored={props.mirrored}
                    />
                    {props.choice ?
                        <div className="wrapper-buttons">
                            <animated.div style={choiceAIn}>
                                <ButtonCustom label={props.choice.choiceA} click={() => choiceHandler(props.choice!.choiceA)} />
                            </animated.div>
                            <animated.div style={choiceBIn}>
                                <ButtonCustom label={props.choice.choiceB} click={() => choiceHandler(props.choice!.choiceB)} />
                            </animated.div>
                        </div>
                        : null}
                </div>
                <Phone
                    receiver={props.receiver}
                    sender={props.sender}
                    phoneOut={phoneOut}
                    messages={props.messages}
                    choiceAnswer={choiceAnswer}
                    additionalMessages={props.additionalMessages}
                    delay={props.phoneDelay}
                    mirrored={props.mirrored}
                />

                <div className="chat-video" style={{ zIndex: '-1' }}>
                    <ReactPlayer
                        url={require(`../assets/videos/${props.videoSrc}`)}
                        width={667}
                        height={375}
                        playing={true}
                        onProgress={(info) => setVideoPlayed(info.playedSeconds)}
                        volume={volume}
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
