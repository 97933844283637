import React, { useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import isDev from '../utils/isDev';
import { inFromBottom, outToBottom } from '../utils/springAnimations';
import Balloon from './Balloon'
import { messageType } from './Chat';

type Props = {
    mirrored?: boolean,
    receiver: string,
    sender: string,
    phoneOut: boolean,
    messages: messageType[],
    additionalMessages?: messageType[],
    choiceAnswer: string | undefined,
    delay: number,
}

export default function Phone(props: Props) {
    const [timePassed, setTimePassed] = useState<number>(0);
    const [preventAutoScroll, setPreventAutoScroll] = useState<boolean>(false);
    const [y,setY] = useState(0);
    const [oldY,setOldY] = useState(0);

    const chatRef = useRef<HTMLDivElement>(null);

    const phoneAnimation = useSpring({
        ...(props.phoneOut ? inFromBottom : {}),
    });

    useEffect(() => {
        if (timePassed < 10000 && !preventAutoScroll) {
            setTimeout(() => {
                setTimePassed(timePassed + 1);
                scrollToBottom();
            }, 100);
        }
    }, [timePassed, preventAutoScroll]);

    useEffect(() => {
        if (y > oldY) {
           if(isDev()) console.log("scrolling up");
          } else if (y + 10 < oldY ) {
            if(isDev()) console.log("scrolling down");
            setPreventAutoScroll(true);
          }
          setOldY(y);
        }, [y, oldY]);

    function scrollToBottom() {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    function handleScroll() {
        if (chatRef.current) {
                setY(chatRef.current.scrollTop);
        }
    }


    return (
        <animated.div style={phoneAnimation} className={`wrapper-phone ${props.mirrored ? 'mirror' : ''}`}>
            <div className="sender-bar">
                <img 
                    src={require(`../assets/images/portraits/${props.sender.toLowerCase()}.png`)}
                    alt=""
                />
                <div className="sender-name">{props.sender}</div>
            </div>
            <div className="wrapper-chat" ref={chatRef} onScroll={handleScroll}>
                {props.messages.map((message, i) => <Balloon
                    classes={props.receiver === message.sender ? 'sender' : 'reciever'}
                    txt={message.txt}
                    delay={(i * (isDev() ? 1000: 3000)) + (props.delay * 1000)}
                    type={message.type}
                />)}
                {props.additionalMessages ?
                    props.additionalMessages.map((message, i) => <Balloon
                        classes={props.receiver === message.sender ? 'sender' : 'reciever'}
                        txt={message.txt}
                        delay={(i * (isDev() ? 1000: 3000))}
                        type={message.type}
                    />)
                    : null}

                {/* answer ballon */}
                {props.choiceAnswer ? <Balloon classes={'sender'} txt={props.choiceAnswer} /> : null}
            </div>
        </animated.div>
    )
}
