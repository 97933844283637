import { useAtomValue } from "jotai";
import React from "react"
import { liedToVisBoxAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import { VisAfterChoiceScreen } from "./VisAfterChoice";
import { VisLastChanceChoiceMultipleStartScreen } from "./VisLastChanceChoiceMultipleStart";

function VisChoiceOutcome(props: ScreenProps) {
    const choice = useAtomValue(liedToVisBoxAtom);
    return (
        <Video
            videoEnded={() => props.onNext(VisAfterChoiceScreen)}
            videoSrc={require(`../../assets/videos/chapter5/${choice ? "cap05-02-option-01_video.mp4" : "cap05-02-option-02_video.mp4"}`)}
        />
    )
};

export const VisChoiceOutcomeScreen: ScreenElement = {
    fn: VisChoiceOutcome
}
