import { useAtomValue } from "jotai";
import React from "react"
import chapter51Text from "../../assets/texts/Capitolo 5-1.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { StopContinueVideoScreen } from "./StopContinueVideo";

function VisOutside(props: ScreenProps) {

    return (
        <VideoGroupChat
            videoSrc="chapter4/capitolo_4_Antonio_03.mp4"
            sender={"ANTONIO, YOUSSEF, VIS"}
            receiver={"ALESSANDRO"}
            messages={messages}
            phoneDelay={4}
            canContinue={() => props.onNext(StopContinueVideoScreen)}
        />
    )
};

export const VisOutsideScreen: ScreenElement = {
    fn: VisOutside
}

const messages: messageType[] = [
    {
        txt: chapter51Text["cap05-04_txt02"].text,
        sender: chapter51Text["cap05-04_txt02"].chara
    },
]