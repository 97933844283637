import { useAtom } from "jotai";
import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { completedChaptersAtom } from "../../atoms/chaptersAtom";
import { ChapterSelectionScreen } from "../../components/ChapterSelection";
import InfoPage from "../../components/InfoPage";

function OnlyYoussef(props: ScreenProps) {
    const [completedChapters, setCompletedChapters] = useAtom(completedChaptersAtom);

    return (
        <InfoPage
            continue={() => {
                if (!completedChapters.includes(2)) {
                    setCompletedChapters([...completedChapters, 2]);
                    localStorage.setItem("completedChapters", JSON.stringify([...completedChapters, 2]));
                }
                props.onNext(ChapterSelectionScreen);
            }}
            text={chapter3Text["cap03-12_txt04"].text}
        />
    )
};

export const OnlyYoussefScreen: ScreenElement = {
    fn: OnlyYoussef
}
