import { useSetAtom } from "jotai";
import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { NoiseNotWaitScreen } from "./NoiseNotWait";
import { NoiseWaitScreen } from "./NoiseWait";

function NoiseChoice(props: ScreenProps) {
    const setPoints = useSetAtom(handlePointsAtom);
    const choiceA: string = chapter3Text["cap03-10-scelta_option-01"].text;
    const choiceB: string = chapter3Text["cap03-10-scelta_option-02"].text;

    function handleChoiceSelected(choice: string) {
        if (choice === choiceA) {
            setPoints(parseInt(chapter3Text["cap03-10-scelta_option-01"].value));
            props.onNext(NoiseNotWaitScreen)
        } else {
            setPoints(parseInt(chapter3Text["cap03-10-scelta_option-02"].value));
            props.onNext(NoiseWaitScreen)
            
        }
    }

    return (
        <VideoChoice
            videoSrc={require(`../../assets/videos/chapter3/choiceYussef.mp4`)}
            choiceDelay={2}
            questionTop="1rem"
            question={chapter3Text["cap03-10-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
    )
};

export const NoiseChoiceScreen: ScreenElement = {
    fn: NoiseChoice
}
