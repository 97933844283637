import { useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { endingAtom } from "../../atoms/endingAtom"
import { pointsAtom } from "../../atoms/pointsAtom"
import { EndingScreen } from "../../components/Ending"
import Video from "../../components/Video"

function StopEnding(props:ScreenProps){
  const points = useAtomValue(pointsAtom);
  const setEnding = useSetAtom(endingAtom)
  return (
    <Video
        videoEnded={() => {
          if(points < 50){
            setEnding(1);
          }else{
            setEnding(0);
          }
          props.onNext(EndingScreen)
        }}
        videoSrc={require(`../../assets/videos/chapter5/cap051-04-01_video03.mp4`)}
    />
  )
};

export const StopEndingScreen: ScreenElement = {
fn: StopEnding
}
