import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import { ListenToSabriChoiceScreen } from "./ListenToSabriChoice";

export function GunSabriChat(props: ScreenProps) {
  return (
    <VideoChat
      videoSrc="chapter4/capitolo_4_Antonio_01.mp4"
      sender={"SABRINA"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={15}
      mirrored={true}
      canContinue={() => props.onNext(ListenToSabriChoiceScreen)}
    />
  );
}

export const GunSabriChatScreen: ScreenElement = {
  fn: GunSabriChat,
};

const messages: messageType[] = [
  {
    txt: chapter4Text['cap04-02-option-01_txt14'].text,
    sender: chapter4Text["cap04-02-option-01_txt14"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt15'].text,
    sender: chapter4Text["cap04-02-option-01_txt15"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt16'].text,
    sender: chapter4Text["cap04-02-option-01_txt16"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt17'].text,
    sender: chapter4Text["cap04-02-option-01_txt17"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt18'].text,
    sender: chapter4Text["cap04-02-option-01_txt18"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt19'].text,
    sender: chapter4Text["cap04-02-option-01_txt19"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt20'].text,
    sender: chapter4Text["cap04-02-option-01_txt20"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt21'].text,
    sender: chapter4Text["cap04-02-option-01_txt21"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt22'].text,
    sender: chapter4Text["cap04-02-option-01_txt22"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt23'].text,
    sender: chapter4Text["cap04-02-option-01_txt23"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt24'].text,
    sender: chapter4Text["cap04-02-option-01_txt24"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt25'].text,
    sender: chapter4Text["cap04-02-option-01_txt25"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt26'].text,
    sender: chapter4Text["cap04-02-option-01_txt26"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt27'].text,
    sender: chapter4Text["cap04-02-option-01_txt27"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt28'].text,
    sender: chapter4Text["cap04-02-option-01_txt28"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt29'].text,
    sender: chapter4Text["cap04-02-option-01_txt29"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt30'].text,
    sender: chapter4Text["cap04-02-option-01_txt30"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt31'].text,
    sender: chapter4Text["cap04-02-option-01_txt31"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt32'].text,
    sender: chapter4Text["cap04-02-option-01_txt32"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt33'].text,
    sender: chapter4Text["cap04-02-option-01_txt33"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-01_txt34'].text,
    sender: chapter4Text["cap04-02-option-01_txt34"].chara
  },
];