import React from "react"
import { a } from "react-spring";
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { GroupChatInfoScreen } from "./GroupChatInfo";


function VisGroup1(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO, ALESSANDRO, VIS"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(GroupChatInfoScreen)}
        />
    )
};

export const VisGroup1Screen: ScreenElement = {
    fn: VisGroup1
}


const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-02_txt04"].text,
        sender: chapter3Text["cap03-02_txt04"].chara
    },
    {
        txt: chapter3Text["cap03-02_txt05"].text,
        sender: chapter3Text["cap03-02_txt05"].chara
    },
]