import React, { useEffect } from "react";
import VideoChat from "../../components/VideoChat";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { messageType } from "../../components/Chat";
import { EnvelopeInfoScreen } from "./EnvelopeInfo";
import isDev from "../../utils/isDev";

export function Chapter4IntroChat(props: ScreenProps) {

  useEffect(() => {
    if (!isDev()) {
        const myEvent = new CustomEvent("chapter_4", {
            detail: {},
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        document.dispatchEvent(myEvent);
    }
}, []);

  return (
    <VideoChat
      videoSrc="chapter4/capitolo_4_Antonio_02.mp4"
      sender={"ALESSANDRO"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={8}
      canContinue={() => props.onNext(EnvelopeInfoScreen)}
    />
  );
}

export const Chapter4IntroChatScreen: ScreenElement = {
  fn: Chapter4IntroChat,
};


const messages: messageType[] = [
  {
    txt: chapter4Text['cap04-01_txt01'].text,
    sender: chapter4Text["cap04-01_txt01"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt02'].text,
    sender: chapter4Text["cap04-01_txt02"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt03'].text,
    sender: chapter4Text["cap04-01_txt03"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt04'].text,
    sender: chapter4Text["cap04-01_txt04"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt05'].text,
    sender: chapter4Text["cap04-01_txt05"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt06'].text,
    sender: chapter4Text["cap04-01_txt06"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt07'].text,
    sender: chapter4Text["cap04-01_txt07"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt08'].text,
    sender: chapter4Text["cap04-01_txt08"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt09'].text,
    sender: chapter4Text["cap04-01_txt09"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt10'].text,
    sender: chapter4Text["cap04-01_txt10"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt11'].text,
    sender: chapter4Text["cap04-01_txt11"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt12'].text,
    sender: chapter4Text["cap04-01_txt12"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt13'].text,
    sender: chapter4Text["cap04-01_txt13"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt14'].text,
    sender: chapter4Text["cap04-01_txt14"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt15'].text,
    sender: chapter4Text["cap04-01_txt15"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt16'].text,
    sender: chapter4Text["cap04-01_txt16"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt17'].text,
    sender: chapter4Text["cap04-01_txt17"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt18'].text,
    sender: chapter4Text["cap04-01_txt18"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt19'].text,
    sender: chapter4Text["cap04-01_txt19"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt20'].text,
    sender: chapter4Text["cap04-01_txt20"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt21'].text,
    sender: chapter4Text["cap04-01_txt21"].chara
  },
  {
    txt: chapter4Text['cap04-01_txt22'].text,
    sender: chapter4Text["cap04-01_txt22"].chara
  },

]