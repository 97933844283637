import { useSetAtom } from "jotai";
import React from "react";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { correctMessagesAtom } from "../../atoms/choicesAtom";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { GroupChatInfoScreen } from "./GroupChatInfo";

export function MessagesChoice(props: ScreenProps) {
    const setCorrectMessages = useSetAtom(correctMessagesAtom)
    const setPoints = useSetAtom(handlePointsAtom);
    const choiceA: string = chapter2Text["cap02-04_02_scelta_option-01"].text;
    const choiceB: string = chapter2Text["cap02-04_02_scelta_option-02"].text;

    function handleChoiceSelected(choice: string) {
        if(choice === choiceA) {
            setPoints(parseInt(chapter2Text["cap02-04_02_scelta_option-01"].value));
            setCorrectMessages(true)
        }else{
            setCorrectMessages(false)
            setPoints(parseInt(chapter2Text["cap02-04_02_scelta_option-02"].value));
        }
        props.onNext(GroupChatInfoScreen)
    }


  return (
    <VideoChoice
            videoSrc={require(`../../assets/videos/chapter2/Capitolo_2_ALESSANDRO.mp4`)}
            choiceDelay={3}
            question={chapter2Text["cap02-04_02-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
  );
}

export const MessagesChoiceScreen:ScreenElement ={
 fn: MessagesChoice,
};