import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { GiovanniOrSabriScreen } from "./GiovanniOrSabri";

export function OpenEnvelopeGroup2(props: ScreenProps) {
  return (
    <VideoGroupChat
      videoSrc="chapter4/capitolo_4_Antonio_06.mp4"
      sender={"ALESSANDRO, YOUSSEF"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={15}
      canContinue={() => props.onNext(GiovanniOrSabriScreen)}
    />
  );
}

export const OpenEnvelopeGroup2Screen: ScreenElement = {
  fn: OpenEnvelopeGroup2,
};

const messages: messageType[] = [
  {
    txt: chapter4Text['cap04-02-option-02_txt27'].text,
    sender: chapter4Text["cap04-02-option-02_txt27"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt28'].text,
    sender: chapter4Text["cap04-02-option-02_txt28"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt29'].text,
    sender: chapter4Text["cap04-02-option-02_txt29"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt30'].text,
    sender: chapter4Text["cap04-02-option-02_txt30"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt31'].text,
    sender: chapter4Text["cap04-02-option-02_txt31"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt32'].text,
    sender: chapter4Text["cap04-02-option-02_txt32"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt33'].text,
    sender: chapter4Text["cap04-02-option-02_txt33"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt34'].text,
    sender: chapter4Text["cap04-02-option-02_txt34"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt35'].text,
    sender: chapter4Text["cap04-02-option-02_txt35"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt36'].text,
    sender: chapter4Text["cap04-02-option-02_txt36"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt37'].text,
    sender: chapter4Text["cap04-02-option-02_txt37"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt38'].text,
    sender: chapter4Text["cap04-02-option-02_txt38"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt39'].text,
    sender: chapter4Text["cap04-02-option-02_txt39"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt40'].text,
    sender: chapter4Text["cap04-02-option-02_txt40"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt41'].text,
    sender: chapter4Text["cap04-02-option-02_txt41"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt42'].text,
    sender: chapter4Text["cap04-02-option-02_txt42"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt43'].text,
    sender: chapter4Text["cap04-02-option-02_txt43"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt44'].text,
    sender: chapter4Text["cap04-02-option-02_txt44"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt45'].text,
    sender: chapter4Text["cap04-02-option-02_txt45"].chara
  },
];