import { useSetAtom } from "jotai";
import React from "react"
import chapter51Text from "../../assets/texts/Capitolo 5-1.json";
import { goOnWithRobberyAtom } from "../../atoms/choicesAtom";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import { VisMessageInfoScreen } from "./VisMessageInfo";


function VisLastChanceChoice(props: ScreenProps) {
    const setRobbery = useSetAtom(goOnWithRobberyAtom)
    const setPoints = useSetAtom(handlePointsAtom);
    const choiceA: string = chapter51Text["cap051-04_scelta_option-01"].text;
    const choiceB: string = chapter51Text["cap051-04_scelta_option-02"].text;

    function handleChoiceSelected(choice: string) {

        if (choice === choiceA) {
            setPoints(parseInt(chapter51Text["cap051-04_scelta_option-01"].value));
            setRobbery(false);
        } else {
            setPoints(parseInt(chapter51Text["cap051-04_scelta_option-02"].value));
            setRobbery(true);
        }
        props.onNext(VisMessageInfoScreen);
    }

    return (
        <VideoChoice
            videoSrc={require(`../../assets/videos/chapter5/cap051-04_video01.mp4`)}
            choiceDelay={36}
            question={chapter51Text["cap051-04-scelta_txt"].text}
            choiceSelected={handleChoiceSelected}
            choice={{
                choiceA,
                choiceB,
            }}
        />
    )
};

export const VisLastChanceChoiceScreen: ScreenElement = {
    fn: VisLastChanceChoice
}
