import React, { useEffect } from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import VideoGroupChat from "../../components/VideoGroupChat";
import isDev from "../../utils/isDev";
import { Chapter3Chat2Screen } from "./Chapter3Chat2";


function Chapter3Intro(props:ScreenProps){

    useEffect(() => {
        if (!isDev()) {
            const myEvent = new CustomEvent("chapter_3", {
                detail: {},
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            document.dispatchEvent(myEvent);
        }
    }, []);

  return (
    <VideoChat
        videoSrc="chapter3/chatTemp.mp4"
        sender={"ANTONIO"}
        receiver={"YOUSSEF"}
        messages={messages}
        phoneDelay={1}
        mirrored={true}
        canContinue={() => props.onNext(Chapter3Chat2Screen)}
    />
  )
};

export const Chapter3IntroScreen: ScreenElement = {
fn: Chapter3Intro
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-01_txt01"].text,
        sender: chapter3Text["cap03-01_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt02"].text,
        sender: chapter3Text["cap03-01_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt03"].text,
        sender: chapter3Text["cap03-01_txt03"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt04"].text,
        sender: chapter3Text["cap03-01_txt04"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt05"].text,
        sender: chapter3Text["cap03-01_txt05"].chara
    },
    {
        txt: chapter3Text["cap03-01_txt06"].text,
        sender: chapter3Text["cap03-01_txt06"].chara
    },
]
 