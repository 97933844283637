import React from "react"
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { VisChatChoiceScreen } from "./VisChatChoice";


function GroupAfterWait(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO, ALESSANDRO"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(VisChatChoiceScreen)}
        />
    )
};

export const GroupAfterWaitScreen: ScreenElement = {
    fn: GroupAfterWait
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-10_option-02_txt04"].text,
        sender: chapter3Text["cap03-10_option-02_txt04"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt05"].text,
        sender: chapter3Text["cap03-10_option-02_txt05"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt06"].text,
        sender: chapter3Text["cap03-10_option-02_txt06"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt07"].text,
        sender: chapter3Text["cap03-10_option-02_txt07"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt08"].text,
        sender: chapter3Text["cap03-10_option-02_txt08"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt09"].text,
        sender: chapter3Text["cap03-10_option-02_txt09"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt10"].text,
        sender: chapter3Text["cap03-10_option-02_txt10"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt11"].text,
        sender: chapter3Text["cap03-10_option-02_txt11"].chara
    },
    {
        txt: chapter3Text["cap03-10_option-02_txt12"].text,
        sender: chapter3Text["cap03-10_option-02_txt12"].chara
    },
]