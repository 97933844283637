import React from "react"
import { messageType } from "../../components/Chat"
import VideoChat from "../../components/VideoChat"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { PicFoundChatIntroScreen } from "./PicFoundChatIntro";

function BullyChat(props: ScreenProps) {
    return (
        <VideoChat
            videoSrc="chapter1/CAPITOLO_01_06.mp4"
            sender={"riccardo"}
            receiver={"ANTONIO"}
            messages={messages}
            phoneDelay={6}
            mirrored={true}
            canContinue={() => props.onNext(PicFoundChatIntroScreen)}
        />
    )
};

export const BullyChatScreen: ScreenElement = {
    fn: BullyChat
}


const messages: messageType[] = [
    {
        txt: chapter1Text['cap01-02_txt02'].text,
        sender: chapter1Text["cap01-02_txt02"].chara
    },
    {
        txt: chapter1Text['cap01-02_txt03'].text,
        sender: chapter1Text["cap01-02_txt03"].chara
    },
]