import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { messageSound } from '../assets/sounds/sounds';
import { useInit } from '../hooks/useInit';

type Props = {
  txt: string,
  classes?: string,
  delay?: number,
  type?: string,
  sender?: string,
}


export default function Balloon(props: Props) {
  const ballonIn = useSpring({ to: { transform: 'translateY(0px)', opacity: 1, display: 'block' }, from: { transform: 'translateY(5%)', opacity: 0, display: 'none' }, delay: props.delay ?? 0 });

  useEffect(() => {
    const audio = new Audio(messageSound);
    setTimeout(() => {
      audio.play();
    }, props.delay ?? 0);
    audio.onended = () => {
      console.log('audio ended')
      audio.srcObject = null;
    }
  }, [props.delay]);

  return (
    <animated.div style={ballonIn} className={"balloon " + (props.classes ?? '')}>
      <div className={`balloon-sender ${props.sender && props.sender !== '' && props.classes !== 'sender' ? 'visible' : 'd-none'}`}>{props.sender}</div>
      {props.type && props.type === 'img' ?
        <img style={{
          width: '100%',
          height: 'auto',
          margin: '0 auto',
          maxHeight: '300px',
          objectFit: 'contain'
        }} src={require(`../assets/images/layout/${props.txt}`)} alt="" />
        :
        props.txt}
    </animated.div>
  )
}
