import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { SmallGroupChat2Screen } from "./SmallGroupChat2";


function GroupChatInfo(props:ScreenProps){
  return (
    <InfoPage
        continue={() => props.onNext(SmallGroupChat2Screen)}
        text={chapter3Text["cap03-03_txt01"].text}
      />
  )
};

export const GroupChatInfoScreen: ScreenElement = {
fn: GroupChatInfo
}
