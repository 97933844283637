import { atom } from "jotai";

export const tellAtom = atom<boolean>(false);

export const withVisAtom = atom<boolean>(false);

export const giovanniAdviceAtom = atom<boolean>(false);

export const correctMessagesAtom = atom<boolean>(false);

export const leaveEnvelopeAtom = atom<boolean>(false);

export const listenToSabriAtom = atom<boolean>(false);

export const sabriOrGiovanniAtom = atom<boolean>(false);

export const liedToVisBoxAtom = atom<boolean>(false);

export const goOnWithRobberyAtom = atom<boolean>(false);
