import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { PictureChatScreen } from "./PictureChat";
import { BullyChatScreen } from "./BullyChat";

function BullyChatIntro(props: ScreenProps) {
    return (
        <InfoPage
            continue={() => props.onNext(BullyChatScreen)}
            text={chapter1Text["cap01-02_txt01"].text}
            />
    )
};

export const BullyChatIntroScreen: ScreenElement = {
    fn: BullyChatIntro
}
