import React from "react";
import { messageType } from "../../components/Chat";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import VideoGroupChat from "../../components/VideoGroupChat";
import { useAtomValue } from "jotai";
import { correctMessagesAtom } from "../../atoms/choicesAtom";
import { AllMessagesInfoScreen } from "./AllMessagesInfo";


export function MessagesChat(props: ScreenProps) {
  const correctMessages = useAtomValue<boolean>(correctMessagesAtom);

  return (
    <VideoGroupChat
      videoSrc={correctMessages ? "chapter2/Capitolo_2_05_ mex corretti.mp4" : "chapter2/Capitolo_2_05_ mex non  corretti.mp4"}
      sender={"ANTONIO, YOUSSEF"}
      receiver={"ALESSANDRO"}
      messages={correctMessages ? messagesA : messagesB}
      phoneDelay={10}
      canContinue={() => props.onNext(AllMessagesInfoScreen)}
      mirrored={true}
    />
  );
}

export const MessagesChatScreen: ScreenElement = {
  fn: MessagesChat,
};

const messagesA: messageType[] = [
  {
    txt: chapter2Text["cap02-04_option-01_txt01"].text,
    sender: chapter2Text["cap02-04_option-01_txt01"].chara
  },
  {
    txt: chapter2Text["cap02-04_option-01_txt02"].text,
    sender: chapter2Text["cap02-04_option-01_txt02"].chara
  },
]
const messagesB: messageType[] = [
  {
    txt: chapter2Text["cap02-04_option-02_txt01"].text,
    sender: chapter2Text["cap02-04_option-02_txt01"].chara
  },
  {
    txt: chapter2Text["cap02-04_option-02_txt02"].text,
    sender: chapter2Text["cap02-04_option-02_txt02"].chara
  },

]