import { useAtomValue, useSetAtom } from "jotai";
import { goOnWithRobberyAtom } from "../../atoms/choicesAtom";
import { endingAtom } from "../../atoms/endingAtom";
import { pointsAtom } from "../../atoms/pointsAtom";
import { EndingScreen } from "../../components/Ending";
import Video from "../../components/Video";
import { ContinueEndingScreen } from "./ContinueEnding";

function StopContinueVideo(props: ScreenProps) {
    const points = useAtomValue(pointsAtom);
    const setEnding = useSetAtom(endingAtom);
    const getRobbery = useAtomValue(goOnWithRobberyAtom)
    return (
        <Video
            videoEnded={() => {
                if (!getRobbery) {
                    if (points < 50) {
                        setEnding(2);
                    }else{
                        setEnding(1);
                    }
                    props.onNext(EndingScreen)
                } else {
                    if (points <= 70) {
                        setEnding(3);
                    }else{
                        setEnding(2);
                    }
                    props.onNext(ContinueEndingScreen)
                }
            }}
            videoSrc={require(`../../assets/videos/chapter5/${!getRobbery ? "cap052-04-01_video01.mp4" : "cap052-04-02_video01.mp4"}`)}
        />
    )
};

export const StopContinueVideoScreen: ScreenElement = {
    fn: StopContinueVideo
}
