import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { NervousChatScreen } from "./NervousChat";


function NervousInfo(props:ScreenProps){
  return (
    <InfoPage
        continue={() => props.onNext(NervousChatScreen)}
        text={chapter3Text["cap03-08_txt01"].text}
      />
  )
};

export const NervousInfoScreen: ScreenElement = {
fn: NervousInfo
}
