import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { GroupWhileGoingScreen } from "./GroupWhileGoing";
import { WarehouseImpatientScreen } from "./WarehouseImpatient";


function WharehouseInfo(props:ScreenProps){
    
  return (
    <InfoPage
        continue={() => props.onNext(WarehouseImpatientScreen)}
        text={chapter3Text["cap03-08_txt01_pre"].text}
      />
  )
};

export const WharehouseInfoScreen: ScreenElement = {
fn: WharehouseInfo
}
