import React from "react";
import VideoChat from "../../components/VideoChat";
import { ListenToSabriChoiceScreen } from "./ListenToSabriChoice";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { MessagesChatScreen } from "../chapter 2/MessagesChat";
import { OpenEnvelopeGroup2Screen } from "./OpenEnvelopeGroup2";

export function OpenEnvelopeGroup1(props: ScreenProps) {
  return (
    <VideoGroupChat
      videoSrc="chapter4/capitolo_4_Antonio_01.mp4"
      sender={"ALESSANDRO, YOUSSEF"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={15}
      canContinue={() => props.onNext(OpenEnvelopeGroup2Screen)}
    />
  );
}

export const OpenEnvelopeGroup1Screen: ScreenElement = {
  fn: OpenEnvelopeGroup1,
};

const messages: messageType[] = [
  {
    txt: chapter4Text['cap04-02-option-02_txt01'].text,
    sender: chapter4Text["cap04-02-option-02_txt01"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt02'].text,
    sender: chapter4Text["cap04-02-option-02_txt02"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt03'].text,
    sender: chapter4Text["cap04-02-option-02_txt03"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt04'].text,
    sender: chapter4Text["cap04-02-option-02_txt04"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt05'].text,
    sender: chapter4Text["cap04-02-option-02_txt05"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt06'].text,
    sender: chapter4Text["cap04-02-option-02_txt06"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt07'].text,
    sender: chapter4Text["cap04-02-option-02_txt07"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt08'].text,
    sender: chapter4Text["cap04-02-option-02_txt08"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt09'].text,
    sender: chapter4Text["cap04-02-option-02_txt09"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt10'].text,
    sender: chapter4Text["cap04-02-option-02_txt10"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt11'].text,
    sender: chapter4Text["cap04-02-option-02_txt11"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt12'].text,
    sender: chapter4Text["cap04-02-option-02_txt12"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt13'].text,
    sender: chapter4Text["cap04-02-option-02_txt13"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt14'].text,
    sender: chapter4Text["cap04-02-option-02_txt14"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt15'].text,
    sender: chapter4Text["cap04-02-option-02_txt15"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt16'].text,
    sender: chapter4Text["cap04-02-option-02_txt16"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt17'].text,
    sender: chapter4Text["cap04-02-option-02_txt17"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt18'].text,
    sender: chapter4Text["cap04-02-option-02_txt18"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt19'].text,
    sender: chapter4Text["cap04-02-option-02_txt19"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt20'].text,
    sender: chapter4Text["cap04-02-option-02_txt20"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt21'].text,
    sender: chapter4Text["cap04-02-option-02_txt21"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt22'].text,
    sender: chapter4Text["cap04-02-option-02_txt22"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt23'].text,
    sender: chapter4Text["cap04-02-option-02_txt23"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt24'].text,
    sender: chapter4Text["cap04-02-option-02_txt24"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt25'].text,
    sender: chapter4Text["cap04-02-option-02_txt25"].chara
  },
  {
    txt: chapter4Text['cap04-02-option-02_txt26'].text,
    sender: chapter4Text["cap04-02-option-02_txt26"].chara
  },
];