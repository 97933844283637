import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { tellAtom, withVisAtom } from "../../atoms/choicesAtom";
import VideoChoice from "../../components/VideoChoice";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import { MessageStartInfoScreen } from "./MessageStartInfo";
import { GiovanniAdviceScreen } from "./GiovanniAdvice";
import { PostZeroChoiceScreen } from "./PostZeroChoice";
import isDev from "../../utils/isDev";


export function Chapter2Intro(props: ScreenProps) {
  const told = useAtomValue(tellAtom);
  const setPoints = useSetAtom(handlePointsAtom);
  const setVis = useSetAtom(withVisAtom);
  const choiceA = chapter2Text["cap02-02_scelta_option-01"].text;
  const choiceB = chapter2Text["cap02-02_scelta_option-02"].text;

  useEffect(() => {
    if (!isDev()) {
        const myEvent = new CustomEvent("chapter_2", {
            detail: {},
            bubbles: true,
            cancelable: true,
            composed: false,
        });

        document.dispatchEvent(myEvent);
    }
}, []);

  function handleChoice(choice: string) {
    if (choice === choiceA) {
      setPoints(parseInt(chapter2Text["cap02-02_scelta_option-01"].value));
      setVis(true);
    } else {
      setPoints(parseInt(chapter2Text["cap02-02_scelta_option-02"].value));
      setVis(false)
    }
    props.onNext(PostZeroChoiceScreen);
  }

  return (

    <VideoChoice
      videoSrc={require(`../../assets/videos/chapter2/${told ? "Capitolo_2_01_confidato.mp4" : "Capitolo_2_01_non_confidato.mp4"}`)}
      choiceDelay={told ? 130 : 140}
      question={chapter2Text["cap02-02-scelta_txt"].text}
      choiceSelected={handleChoice}
      choice={{
        choiceA,
        choiceB,
      }}
    />
  );
}

export const Chapter2IntroScreen: ScreenElement = {
  fn: Chapter2Intro,
};


