import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { GroupChatPicInfoScreen } from "./GroupChatPicInfo";

function GroupChatInfo(props:ScreenProps){
  return (
    <InfoPage
    continue={() => props.onNext(GroupChatPicInfoScreen)}
    text={chapter1Text["cap01-06_txt01"].text}
    />
  )
};

export const GroupChatInfoScreen: ScreenElement = {
fn: GroupChatInfo
}
