import { useSetAtom } from "jotai";
import React, { useState } from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import { messageType } from "../../components/Chat";
import ChoiceOverlay from "../../components/ChoiceOverlay";
import VideoGroupChat from "../../components/VideoGroupChat";
import { VisChatInfoScreen } from "./VisChatInfo";
import { GroupWhileGoingInfoScreen } from "./GroupWhileGoingInfo";

function VisChatChoice(props: ScreenProps) {
    const [messagesToUse, setMessagesToUse] = React.useState<messageType[]>([]);
    const setPoints = useSetAtom(handlePointsAtom)

    const [chatStep, setChatStep] = useState<number>(0);

    function handleContinue(currentStep: number) {
        if (currentStep === 2) {
            props.onNext(VisChatInfoScreen);
        } else {
            setChatStep(currentStep + 1);
        }
    }

    function handleChoice(choice: string) {
        console.log("choice: " + choice);
        if (choice === chapter3Text['cap03-11-option-01'].text) {
            setPoints(parseInt(chapter3Text['cap03-11-option-01'].value))
            setMessagesToUse([...additionalA, ...commonEnding]);
        } else {
            setPoints(parseInt(chapter3Text['cap03-11-option-02'].value))
            setMessagesToUse([...additionalB, ...commonEnding]);
        }

        setChatStep(2);
    }

    return (
        <>
            <VideoGroupChat
                videoSrc="chapter3/chatTemp.mp4"
                sender={"ANTONIO, ALESSANDRO, VIS"}
                receiver={"YOUSSEF"}
                messages={messages}
                additionalMessages={messagesToUse}
                phoneDelay={1}
                mirrored={true}
                canContinue={() => handleContinue(chatStep)}
            />

            {chatStep === 1 ?
                <ChoiceOverlay
                    portrait={""}
                    question={chapter3Text['cap03-11-scelta_txt'].text}
                    choiceA={chapter3Text['cap03-11-option-01'].text}
                    choiceB={chapter3Text['cap03-11-option-02'].text}
                    choiceDone={handleChoice}
                />
                : null}
        </>
    )
};

export const VisChatChoiceScreen: ScreenElement = {
    fn: VisChatChoice
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-11_txt01"].text,
        sender: chapter3Text["cap03-11_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-11_txt02"].text,
        sender: chapter3Text["cap03-11_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-11_txt03"].text,
        sender: chapter3Text["cap03-11_txt03"].chara
    },
]

const additionalA: messageType[] = [
    {
        txt: chapter3Text["cap03-11-option-01_txt01"].text,
        sender: chapter3Text["cap03-11-option-01_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-11-option-01_txt02"].text,
        sender: chapter3Text["cap03-11-option-01_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-11-option-01_txt03"].text,
        sender: chapter3Text["cap03-11-option-01_txt03"].chara
    },
    {
        txt: chapter3Text["cap03-11-option-01_txt04"].text,
        sender: chapter3Text["cap03-11-option-01_txt04"].chara
    },
]

const additionalB: messageType[] = [
    {
        txt: chapter3Text["cap03-11-option-02_txt01"].text,
        sender: chapter3Text["cap03-11-option-02_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-11-option-02_txt02"].text,
        sender: chapter3Text["cap03-11-option-02_txt02"].chara
    },
]

const commonEnding: messageType[] = [
    {
        txt: chapter3Text["cap03-12_txt01"].text,
        sender: chapter3Text["cap03-12_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-12_txt02"].text,
        sender: chapter3Text["cap03-12_txt02"].chara
    },
]