import React from "react";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import { MessageStartInfoScreen } from "./MessageStartInfo";

export function GiovanniAdvice(props: ScreenProps) {

  return (
          <VideoChat
              videoSrc="chapter2/Capitolo_2_03_confidarsi con Giovanni.mp4"
              sender={"giovanni"}
              receiver={"ALESSANDRO"}
              messages={messages}
              phoneDelay={10}
              mirrored={true}
              canContinue={() => props.onNext(MessageStartInfoScreen)}
          />
  );
}

export const GiovanniAdviceScreen:ScreenElement ={
 fn: GiovanniAdvice,
};

const messages: messageType[] = [
  {
      txt: chapter2Text['cap02-03_option-02_txt01'].text,
      sender: chapter2Text["cap02-03_option-02_txt01"].chara
  },
  {
      txt: chapter2Text['cap02-03_option-02_txt02'].text,
      sender: chapter2Text["cap02-03_option-02_txt02"].chara
  },
  {
      txt: chapter2Text['cap02-03_option-02_txt03'].text,
      sender: chapter2Text["cap02-03_option-02_txt03"].chara
  },
  {
      txt: chapter2Text['cap02-03_option-02_txt04'].text,
      sender: chapter2Text["cap02-03_option-02_txt04"].chara
  },
  {
      txt: chapter2Text['cap02-03_option-02_txt05'].text,
      sender: chapter2Text["cap02-03_option-02_txt05"].chara
  },

]