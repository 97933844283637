import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { GroupChatInfoScreen } from "./GroupChatInfo";

function OneLeftInfo(props:ScreenProps){
  return (
    <InfoPage
    continue={() => props.onNext(GroupChatInfoScreen)}
    text={chapter1Text["cap01-05_txt01"].text}
    />
  )
};

export const OneLeftInfoScreen: ScreenElement = {
fn: OneLeftInfo
}
