import React from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { GiovanniImpact2Screen } from "./GiovanniImpact2";

function SmallGroupChat(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter3/chatTemp.mp4"
            sender={"ANTONIO, ALESSANDRO"}
            receiver={"YOUSSEF"}
            messages={messages}
            phoneDelay={1}
            mirrored={true}
            canContinue={() => props.onNext(GiovanniImpact2Screen)}
        />
    )
};

export const SmallGroupChatScreen: ScreenElement = {
    fn: SmallGroupChat
}

const messages: messageType[] = [
    {
        txt: chapter3Text["cap03-02_txt01"].text,
        sender: chapter3Text["cap03-02_txt01"].chara
    },
    {
        txt: chapter3Text["cap03-02_txt02"].text,
        sender: chapter3Text["cap03-02_txt02"].chara
    },
    {
        txt: chapter3Text["cap03-02_txt03"].text,
        sender: chapter3Text["cap03-02_txt03"].chara
    },
]