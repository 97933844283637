import React from "react";
import { messageType } from "../../components/Chat";
import { MessageStartInfoScreen } from "./MessageStartInfo";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import VideoGroupChat from "../../components/VideoGroupChat";
import { MessagesChatScreen } from "./MessagesChat";


export function GroupChat(props: ScreenProps) {
  return (
    <VideoGroupChat
      videoSrc="chapter2/Capitolo_2_04_messaggi con gli altri.mp4"
      sender={"ANTONIO, YOUSSEF"}
      receiver={"ALESSANDRO"}
      messages={messages}
      phoneDelay={0}
      canContinue={() => props.onNext(MessagesChatScreen)}
    />
  );
}

export const GroupChatScreen: ScreenElement = {
  fn: GroupChat,
};

const messages: messageType[] = [
  {
    txt: chapter2Text["cap02-04_txt03"].text,
    sender: chapter2Text["cap02-04_txt03"].chara
  },
  {
    txt: chapter2Text["cap02-04_txt04"].text,
    sender: chapter2Text["cap02-04_txt04"].chara
  },
  {
    txt: chapter2Text["cap02-04_txt05"].text,
    sender: chapter2Text["cap02-04_txt05"].chara
  },

]