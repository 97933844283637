import React from "react"
import { messageType } from "../../components/Chat"
import VideoGroupChat from "../../components/VideoGroupChat"
import chapter51Text from "../../assets/texts/Capitolo 5-1.json";
import { StopEndingScreen } from "./StopEnding";


function StopChat(props: ScreenProps) {
    return (
        <VideoGroupChat
            videoSrc="chapter1/CAPITOLO_01_09.mp4"
            sender={"ANTONIO, YOUSSEF, VIS"}
            receiver={"ALESSANDRO"}
            messages={messages}
            phoneDelay={0}
            canContinue={() => props.onNext(StopEndingScreen)}
        />
    )
};

export const StopChatScreen: ScreenElement = {
    fn: StopChat
}

const messages: messageType[] = [
    {
        txt: chapter51Text["cap051-04-01_txt01"].text,
        sender: chapter51Text["cap051-04-01_txt01"].chara
    },
    {
        txt: chapter51Text["cap051-04-01_txt02"].text,
        sender: chapter51Text["cap051-04-01_txt02"].chara
    },
]