import { useAtomValue } from "jotai";
import React from "react"
import { correctMessagesAtom, giovanniAdviceAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import { SmallGroupChatScreen } from "./SmallGroupChat";

function GiovanniImpact1(props: ScreenProps) {
  const correctMessages = useAtomValue<boolean>(correctMessagesAtom);

    const giovanniAdvice = useAtomValue(giovanniAdviceAtom);

    return (
        <Video
            videoEnded={() => props.onNext(SmallGroupChatScreen)}
            videoSrc={require(`../../assets/videos/chapter3/${correctMessages ? "capitolo_3 _1  ok.mp4" : "capitolo_3_1 err.mp4"}`)}
        />
    )
};

export const GiovanniImpact1Screen: ScreenElement = {
    fn: GiovanniImpact1
}
