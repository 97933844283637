import React from "react"
import Video from "../../components/Video"
import { WharehouseInfoScreen } from "./WharehouseInfo"

function WarehouseSketchy(props: ScreenProps) {
    return (
        <Video
            videoEnded={() => props.onNext(WharehouseInfoScreen)}
            videoSrc={require(`../../assets/videos/chapter3/cap3_magazzino_circospezione.mp4`)}
        />
    )
};

export const WarehouseSketchyScreen: ScreenElement = {
    fn: WarehouseSketchy
}
