import React from "react"
import { messageType } from "../../components/Chat"
import VideoChat from "../../components/VideoChat"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { PicFoundChatIntroScreen } from "./PicFoundChatIntro";
import ChoiceOverlay from "../../components/ChoiceOverlay";
import { BullyChatNextIntroScreen } from "./BullyChatNextIntro";
import { BurningInfoScreen } from "./BurningInfo";
import { ReliefInfoScreen2 } from "./ReliefInfo2";

function BullyChatNext(props: ScreenProps) {
    const [messagesToUse, setMessagesToUse] = React.useState<messageType[]>([]);
    const [chatStep, setChatStep] = React.useState<number>(0);
    const [shortCut, setShortCut] = React.useState<boolean>(false);
    const [longCut, setLongCut] = React.useState<boolean>(false);

    function handleChoice(choice: string, step: number, currentAdditionalMessages: messageType[]) {

        if (step === 1) {
            if (choice === chapter1Text['cap01-04_scelta_option-01'].text) {
                setMessagesToUse([...currentAdditionalMessages, ...additionalA]);
                setShortCut(true);
            } else {
                setMessagesToUse([...currentAdditionalMessages, ...additionalB]);
            }

            setChatStep(2);
        }

        if (step === 3) {
            if (choice === chapter1Text['cap01-04-02_scelta_option-01'].text) {
                setMessagesToUse([...currentAdditionalMessages, ...additionalA2]);
                setShortCut(true);
            } else {
                setMessagesToUse([...currentAdditionalMessages, ...additionalB2]);
                setLongCut(true);
            }

            setChatStep(4);
        }
    }

    return (
        <>
            <VideoChat
                videoSrc="chapter1/CAPITOLO_01_08.mp4"
                sender={"riccardo"}
                receiver={"ANTONIO"}
                messages={messages}
                additionalMessages={messagesToUse}
                phoneDelay={15}
                mirrored={true}
                canContinue={() => {
                    setChatStep(chatStep + 1);
                    if(shortCut) props.onNext(BurningInfoScreen)
                    if(longCut) props.onNext(ReliefInfoScreen2)
                } }
            />

            {chatStep === 1 ?
                <ChoiceOverlay
                    portrait={""}
                    question={chapter1Text['cap01-04-scelta_txt'].text}
                    choiceA={chapter1Text['cap01-04_scelta_option-01'].text}
                    choiceB={chapter1Text['cap01-04_scelta_option-02'].text}
                    choiceDone={(arg:string) => handleChoice(arg, chatStep, messagesToUse)}
                />
                : null}

            {chatStep === 3 ?
                <ChoiceOverlay
                    portrait={""}
                    question={chapter1Text['cap01-04-02-scelta_txt'].text}
                    choiceA={chapter1Text['cap01-04-02_scelta_option-01'].text}
                    choiceB={chapter1Text['cap01-04-02_scelta_option-02'].text}
                    choiceDone={(arg:string) => handleChoice(arg, chatStep, messagesToUse)}
                />
                : null}
        </>

    )
};

export const BullyChatNextScreen: ScreenElement = {
    fn: BullyChatNext
}


const messages: messageType[] = [
    {
        txt: chapter1Text['cap01-04_txt02'].text,
        sender: chapter1Text["cap01-04_txt02"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt03'].text,
        sender: chapter1Text["cap01-04_txt03"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt04'].text,
        sender: chapter1Text["cap01-04_txt04"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt05'].text,
        sender: chapter1Text["cap01-04_txt05"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt06'].text,
        sender: chapter1Text["cap01-04_txt06"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt07'].text,
        sender: chapter1Text["cap01-04_txt07"].chara
    },
    {
        txt: chapter1Text['cap01-04_txt08'].text,
        sender: chapter1Text["cap01-04_txt08"].chara
    },
]

const additionalA: messageType[] = [
    {
        txt: chapter1Text['cap01-04-option-01_txt01'].text,
        sender: chapter1Text["cap01-04-option-01_txt01"].chara
    },
]

const additionalB: messageType[] = [
    {
        txt: chapter1Text['cap01-04-option-02_txt01'].text,
        sender: chapter1Text["cap01-04-option-02_txt01"].chara
    },
]
const additionalA2: messageType[] = [
    {
        txt: chapter1Text['cap01-04-02-option-01_txt01'].text,
        sender: chapter1Text["cap01-04-02-option-01_txt01"].chara
    },
]

const additionalB2: messageType[] = [
    {
        txt: chapter1Text['cap01-04-02-option-02_txt01'].text,
        sender: chapter1Text["cap01-04-02-option-02_txt01"].chara
    },
    {
        txt: chapter1Text['cap01-04-02-option-02_txt02'].text,
        sender: chapter1Text["cap01-04-02-option-02_txt02"].chara
    },
]