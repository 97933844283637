import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { OneLeftInfoScreen } from "./OneLeftInfo";

function ReliefInfo(props:ScreenProps){
  return (
    <InfoPage
    continue={() => props.onNext(OneLeftInfoScreen)}
    text={chapter1Text["cap01-04-option-01_txt03"].text}
    />
  )
};

export const ReliefInfoScreen: ScreenElement = {
fn: ReliefInfo
}
