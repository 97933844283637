import React from "react";
import InfoPage from "../../components/InfoPage";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { VisGroupDoneScreen } from "./VisGroupDone";
import { AudioMessageScreen } from "./AudioMessage";

export function AudioMessageInfo(props: ScreenProps) {
  return (
    <InfoPage
      continue={() => props.onNext(AudioMessageScreen)}
      text={chapter2Text["cap02-05_txt04"].text}
    />
  );
}

export const AudioMessageInfoScreen: ScreenElement = {
  fn: AudioMessageInfo,
};
