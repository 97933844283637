import { useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { endingAtom } from "../../atoms/endingAtom";
import { pointsAtom } from "../../atoms/pointsAtom";
import { EndingScreen } from "../../components/Ending"
import Video from "../../components/Video"

function ContinueEnding(props: ScreenProps) {
    const points = useAtomValue(pointsAtom);
    const setEnding = useSetAtom(endingAtom);
    return (
        <Video
            videoEnded={() => {
                if(points < 70){
                    setEnding(2);
                }else{
                    setEnding(1);
                }
                props.onNext(EndingScreen)
            }}
            videoSrc={require(`../../assets/videos/chapter5/cap051-04-02_video02.mp4`)}
        />
    )
};

export const ContinueEndingScreen: ScreenElement = {
    fn: ContinueEnding
}
