export const inFromLeft:SpringAnimation = {
  to: { transform: 'translateX(0px)', opacity: 1 },
  from: { transform: 'translateX(-1000px)', opacity: 0 },
}

export const outToLeft:SpringAnimation = {
  to: { transform: 'translateX(-1000px)', opacity: 0 },
  from: { transform: 'translateX(0px)', opacity: 1 },
}

export const inFromRight:SpringAnimation = {
  to: { transform: 'translateX(0px)', opacity: 1 },
  from: { transform: 'translateX(1000px)', opacity: 0 },
}

export const outToRight:SpringAnimation = {
  to: { transform: 'translateX(1000px)', opacity: 0 },
  from: { transform: 'translateX(0px)', opacity: 1 },
}

export const inFromBottom:SpringAnimation = {
  to: { transform: 'translateY(0px)', opacity: 1 },
  from: { transform: 'translateY(1000px)', opacity: 0 },
}

export const outToBottom:SpringAnimation = {
  to: { transform: 'translateY(1000px)', opacity: 0 },
  from: { transform: 'translateY(0px)', opacity: 1 },
}