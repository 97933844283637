import React, { useState } from "react"
import chapter3Text from "../../assets/texts/Capitolo 3.json";
import InfoPage from "../../components/InfoPage";
import { GroupAfterWaitScreen } from "./GroupAfterWait";

function WaitMultiNarration(props: ScreenProps) {
    const [step, setStep] = useState(0);

    return (
        <>
            {step === 0 &&
                <InfoPage
                    continue={() => setStep(1)}
                    text={chapter3Text["cap03-10_option-02_txt01"].text}
                />
            }
            {step === 1 &&
                <InfoPage
                    continue={() => setStep(2)}
                    text={chapter3Text["cap03-10_option-02_txt02"].text}
                />}

            {step === 2 &&
                <InfoPage
                    continue={() => props.onNext(GroupAfterWaitScreen)}
                    text={chapter3Text["cap03-10_option-02_txt03"].text}
                />
            }
        </>
    )
};

export const WaitMultiNarrationScreen: ScreenElement = {
    fn: WaitMultiNarration
}
