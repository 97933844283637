import React from "react"
import Video from "../../components/Video"
import { VisLastChanceChoiceScreen } from "./VisLastChanceChoice"

function PostVisOutcome(props:ScreenProps){
  return (
    <Video
        videoEnded={() => props.onNext(VisLastChanceChoiceScreen)}
        videoSrc={require(`../../assets/videos/chapter5/cap05-03_video.mp4`)}
    />
  )
};

export const PostVisOutcomeScreen: ScreenElement = {
fn: PostVisOutcome
}
