import React from "react";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import { MessagesChatScreen } from "../chapter 2/MessagesChat";
import { EnvelopeChoiceScreen } from "./EnvelopeChoice";

export function GroupEnvelopeChat(props: ScreenProps) {
    return (
      <VideoGroupChat
      videoSrc="chapter4/capitolo_4_Antonio_04.mp4"
      sender={"ALESSANDRO, YOUSSEF"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={3}
      canContinue={() => props.onNext(EnvelopeChoiceScreen)}
    />
    );
}

export const GroupEnvelopeChatScreen:ScreenElement ={
 fn: GroupEnvelopeChat,
};


const messages: messageType[] = [
  {
    txt: chapter4Text["cap04-02_txt02"].text,
    sender: chapter4Text["cap04-02_txt02"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt03"].text,
    sender: chapter4Text["cap04-02_txt03"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt04"].text,
    sender: chapter4Text["cap04-02_txt04"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt05"].text,
    sender: chapter4Text["cap04-02_txt05"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt06"].text,
    sender: chapter4Text["cap04-02_txt06"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt07"].text,
    sender: chapter4Text["cap04-02_txt07"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt08"].text,
    sender: chapter4Text["cap04-02_txt08"].chara
  },
  {
    txt: chapter4Text["cap04-02_txt09"].text,
    sender: chapter4Text["cap04-02_txt09"].chara
  },
]