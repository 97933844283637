import React from "react";
import InfoPage from "../../components/InfoPage";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { TitleScreen } from "../../components/TitlePage";
import { MessagesChoiceScreen } from "./MessagesChoice";

export function MessageStartInfo(props: ScreenProps) {
    return (
      <InfoPage
        continue={() => props.onNext(MessagesChoiceScreen)}
        text={chapter2Text["cap02-04_txt01"].text}
      />
    );
}

export const MessageStartInfoScreen:ScreenElement ={
 fn: MessageStartInfo,
};
