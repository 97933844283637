import { useAtomValue } from "jotai";
import React from "react"
import { correctMessagesAtom, giovanniAdviceAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import { VisGroup1Screen } from "./VisGroup1";

function GiovanniImpact2(props: ScreenProps) {
  const correctMessages = useAtomValue<boolean>(correctMessagesAtom);
    
    const giovanniAdvice = useAtomValue(giovanniAdviceAtom);

    return (
        <Video
            videoEnded={() => props.onNext(VisGroup1Screen)}
            videoSrc={require(`../../assets/videos/chapter3/${correctMessages ? "capitolo_3_2 ok.mp4" : "capitolo_3_2 err.mp4"}`)}
        />
    )
};

export const GiovanniImpact2Screen: ScreenElement = {
    fn: GiovanniImpact2
}
