import React from 'react'
import settings from '../assets/images/icons/settings.png';

type Props = {
  click: () => void
}

export default function SettingsFragment(props: Props) {
  return (
    <img className='settings' src={settings} alt="settings" onClick={props.click}/>
  )
}
