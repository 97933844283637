import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { useSpring, animated } from 'react-spring';
import { IconPlay } from '../assets/images/icons/icons';
import { outToLeft, inFromLeft } from '../utils/springAnimations';
import { useInit } from '../hooks/useInit';
import ButtonCustom from './ButtonCustom';
import { useAtomValue } from 'jotai';
import { getAudioSettingAtom } from '../atoms/settingsAtom';
import isDev from '../utils/isDev';

type Props = {
  videoSrc: string,
  choiceDelay: number,
  question: string,
  questionTop?: string,
  mirrored?: boolean,
  choiceSelected: (choice: string) => void,
  choice: {
    choiceA: string,
    choiceB: string,
  }
}

export default function VideoChoice(props: Props) {
  const volume = useAtomValue(getAudioSettingAtom)
  const [playing, setPlaying] = useState<boolean>(true);
  const [videoPlayed, setVideoPlayed] = useState<number>(0);
  const [choiceVisible, setChoiceVisible] = useState<boolean>(false);
  const [choiceAnswer, setChoiceAnswer] = useState<string | undefined>(undefined);
  const choiceAIn = useSpring({
    to: { transform: !choiceAnswer ? 'translateX(0px)' : 'translateX(1000px)', opacity: !choiceAnswer ? 1 : 0 },
    from: { transform: !choiceAnswer ? 'translateX(1000px)' : 'translateX(0px)', opacity: !choiceAnswer ? 0 : 1 },
  });
  const choiceBIn = useSpring({
    ...(choiceAnswer ? outToLeft : inFromLeft),
  });

  useEffect(() => {
    if (videoPlayed + 1 >= props.choiceDelay) {
      setChoiceVisible(true);
    }
  }, [videoPlayed, props.choiceDelay]);

  function choiceHandler(choice: string) {
    setChoiceAnswer(choice);
    props.choiceSelected(choice);
  }

  return (
    <React.Fragment>
      <div className="game-box inner">
        <div className="custom-modal">
        {isDev() && <div style={{position:'absolute', right:0, top:0, backgroundColor: 'white', zIndex:20}}>{props.videoSrc}</div>}
          {choiceVisible || isDev() ?
            <React.Fragment>
              <div className="stripe" style={{
                left: '0px',
                width: '100%',
                top: '35%',
                height: '45%',
                backgroundColor: 'transparent',

              }}>
                <p className={`${props.mirrored ? 'text-start' : 'text-end'}`} style={{
                  position: 'absolute',
                  zIndex: '2',
                  top: props.questionTop ?? '3rem',
                  right: props.mirrored ? 'unset' : '4rem',
                  left: props.mirrored ? '4rem' : 'unset',
                  width: '50%',
                  fontSize: '1.6rem',
                }}>{props.question}</p>
              </div>
              <div className="wrapper-buttons" style={{ position: 'absolute', zIndex: 2, bottom: '2rem', left: props.mirrored ? 'unset' : '3rem', right: props.mirrored ? '3rem' : 'unset' }}>
                <animated.div style={choiceAIn}>
                  <ButtonCustom label={props.choice.choiceA} click={() => choiceHandler(props.choice!.choiceA)} style={{ marginBottom: '2rem' }} />
                </animated.div>
                <animated.div style={choiceBIn}>
                  <ButtonCustom label={props.choice.choiceB} click={() => choiceHandler(props.choice!.choiceB)} />
                </animated.div>
              </div>

            </React.Fragment> : null}

          <ReactPlayer
            url={props.videoSrc}
            width={667}
            height={375}
            playing={playing}
            onProgress={(info) => setVideoPlayed(info.playedSeconds)}
            volume={volume}
            config={{ file: { attributes: { playsInline: true, }, }, }}
          />
          <div className={`video-controls ${playing ? 'd-none' : 'visible'}`} onClick={() => setPlaying(true)} style={{
            position: 'absolute',
            bottom: '1rem',
            left: '50%',
            transform: 'translateX(-50%)'
          }}>
            <ButtonCustom label={"Inizia"} click={() => setPlaying(true)} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}