import React, { useEffect } from "react"
import Video from "../../components/Video"
import isDev from "../../utils/isDev";
import { VisAskChoiceScreen } from "./VisAskChoice"

function ChapterIntro02(props: ScreenProps) {
    useEffect(() => {
        if (!isDev()) {
            const myEvent = new CustomEvent("chapter_5", {
                detail: {},
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            document.dispatchEvent(myEvent);
        }
    }, []);
    return (
        <Video
            videoEnded={() => props.onNext(VisAskChoiceScreen)}
            videoSrc={require(`../../assets/videos/chapter5/cap052-01-option-04-02-02-02_video.mp4`)}
        />
    )
};

export const ChapterIntro02Screen: ScreenElement = {
    fn: ChapterIntro02
}
