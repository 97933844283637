import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { leaveEnvelopeAtom } from "../../atoms/choicesAtom";
import Video from "../../components/Video";
import isDev from "../../utils/isDev";
import { VisBoxScreen } from "./VisBox";

export function Chapter51Intro(props: ScreenProps) {
    const leavedEnvelope = useAtomValue(leaveEnvelopeAtom);

    useEffect(() => {
        if (!isDev()) {
            const myEvent = new CustomEvent("chapter_5", {
                detail: {},
                bubbles: true,
                cancelable: true,
                composed: false,
            });

            document.dispatchEvent(myEvent);
        }
    }, []);
    return (
        <Video
            videoEnded={() => props.onNext(VisBoxScreen)}
            videoSrc={require(`../../assets/videos/chapter5/${leavedEnvelope ? "cap051-01-option-04-02-01-01.mp4" : "cap051-01-option-04-02-02-01.mp4"}`)}
        />
    );
}

export const Chapter51IntroScreen: ScreenElement = {
    fn: Chapter51Intro,
};
