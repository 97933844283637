import video1 from './CAPITOLO_01_01.mp4';
import video2 from './CAPITOLO_01_02.mp4';
import video5 from './CAPITOLO_01_05.mp4';
import video6 from './CAPITOLO_01_06.mp4';
import video7 from './CAPITOLO_01_07.mp4';
import video8 from './CAPITOLO_01_08.mp4';
import video9 from './CAPITOLO_01_09.mp4';
import video10 from './CAPITOLO_01_10.mp4';





export const chapter1Videos:{[key:string]:string} = {
    'video1': video1,
    'video2': video2,
    'video5': video5,
    'video6': video6,
    'video7': video7,
    'video8': video8,
    'video9': video9,
    'video10': video10,

}