import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { IconPlay } from '../assets/images/icons/icons';
import { getAudioSettingAtom } from '../atoms/settingsAtom';
import isDev from '../utils/isDev';

type Props = {
  style?: React.CSSProperties;
  playing?: boolean,
  videoSrc: string,
  videoEnded: () => void,
};

export default function Video(props: Props) {

  const [playing, setPlaying] = useState(true);
  const volume = useAtomValue(getAudioSettingAtom);


  return (
    <React.Fragment>
      <div className="game-box inner">
        <div className="custom-modal">
          {isDev() && <button style={{ position: 'absolute', left: 0, top: 0, padding: '2rem', backgroundColor: 'red', color: 'white', zIndex: 20 }} onClick={props.videoEnded}>skip</button>}
          {isDev() && <div style={{ position: 'absolute', right: 0, top: 0, backgroundColor: 'white', zIndex: 20 }} onClick={props.videoEnded}>{props.videoSrc}</div>}
          <div style={{ position: 'absolute', ...props.style }}>
            <ReactPlayer
              url={props.videoSrc}
              width={667}
              height={375}
              playing={props.playing !== undefined ? props.playing : true}
              onEnded={props.videoEnded}
              volume={volume}
              config={{ file: { attributes: { playsInline: true, }, }, }}
            />
          </div>
          <div className={`video-controls ${playing ? 'd-none' : 'visible'}`} onClick={() => setPlaying(true)}>
            <IconPlay className="play" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}