
export type TextIntro = {
  label: string,
  classes?: string,
  click: () => void,
}

export default function TextIntro(props: TextIntro) {

  return (
    <div className={props.classes}>
      <div onClick={props.click} className="d-inline-flex align-items-center" style={{ cursor: 'pointer' }}>
        <div className="icon-back white mr-2"></div>
        <span>Back</span>
      </div>
      <p>{props.label}</p>
    </div>
  )
}
