import { useAtomValue, useSetAtom } from "jotai";
import { giovanniAdviceAtom, withVisAtom } from "../../atoms/choicesAtom";
import chapter2Text from "../../assets/texts/Capitolo 2.json";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import { MessageStartInfoScreen } from "./MessageStartInfo";
import { messageType } from "../../components/Chat";
import VideoChat from "../../components/VideoChat";
import { GiovanniAdviceScreen } from "./GiovanniAdvice";
import { useState } from "react";
import ChoiceOverlay from "../../components/ChoiceOverlay";

export function PostZeroChoice(props: ScreenProps) {
  const setGiovanniAdvice = useSetAtom(giovanniAdviceAtom);
  const [chatStep, setChatStep] = useState<number>(0);
  const setPoints = useSetAtom(handlePointsAtom);
  const vis = useAtomValue(withVisAtom);
  const choiceA = chapter2Text["cap02-03_scelta_option-01"].text;

  function handleChoice(choice: string) {
    if (choice === choiceA) {
      setPoints(parseInt(chapter2Text["cap02-03_scelta_option-01"].value));
      setGiovanniAdvice(false);
      props.onNext(MessageStartInfoScreen);
    } else {
      setPoints(parseInt(chapter2Text["cap02-03_scelta_option-02"].value));
      setGiovanniAdvice(true);
      props.onNext(GiovanniAdviceScreen);
    }
  }

  return (
    <>
      <VideoChat
        videoSrc={`chapter2/${vis ? "Capitolo_2_02_con Vis.mp4" : "Capitolo_2_02_opporsi a Vis.mp4"}`}
        sender={"giovanni"}
        receiver={"ALESSANDRO"}
        messages={messages}
        phoneDelay={44}
        mirrored={true}
        canContinue={() => {
          setChatStep(chatStep + 1);
        }}
      />
      {chatStep === 1 ?
        <ChoiceOverlay
          portrait={""}
          question={chapter2Text['cap02-03-scelta_txt'].text}
          choiceA={chapter2Text['cap02-03_scelta_option-01'].text}
          choiceB={chapter2Text['cap02-03_scelta_option-02'].text}
          choiceDone={(arg: string) => handleChoice(arg)}
        />
        : null}
    </>
  );
}

export const PostZeroChoiceScreen: ScreenElement = {
  fn: PostZeroChoice,
};


const messages: messageType[] = [
  {
    txt: chapter2Text['cap02-03_txt1'].text,
    sender: chapter2Text["cap02-03_txt1"].chara
  },
  {
    txt: chapter2Text['cap02-03_txt2'].text,
    sender: chapter2Text["cap02-03_txt2"].chara
  },
  {
    txt: chapter2Text['cap02-03_txt3'].text,
    sender: chapter2Text["cap02-03_txt3"].chara
  },
  {
    txt: chapter2Text['cap02-03_txt4'].text,
    sender: chapter2Text["cap02-03_txt4"].chara
  },
  {
    txt: chapter2Text['cap02-03_txt5'].text,
    sender: chapter2Text["cap02-03_txt5"].chara
  },
]