import React from "react"
import Video from "../../components/Video"
import { StopV2Screen } from "./StopV2"

function StopV1(props:ScreenProps){
  return (
    <Video
        videoEnded={() => props.onNext(StopV2Screen)}
        videoSrc={require(`../../assets/videos/chapter5/cap051-04-01_video01.mp4`)}
    />
  )
};

export const StopV1Screen: ScreenElement = {
fn: StopV1
}
