import React, { useState } from "react";
import ButtonCustom from "../../components/ButtonCustom";
import Video from "../../components/Video";
import { GroupChatInfoScreen } from "./GroupChatInfo";
import { introVideoScreen } from "./IntroVideo";

function StartVideo(props: ScreenProps) {
  const [playing, setPlaying] = useState(false);

  return (
    <>
      <Video
        videoSrc={require(`../../assets/videos/chapter1/Capitolo_0.mp4`)}
        videoEnded={() => props.onNext(introVideoScreen)}
        playing={playing}
        style={{ zIndex: -1 }}
      />
      <div className={`video-controls ${playing ? 'd-none' : 'visible'}`} onClick={() => setPlaying(true)} style={{
        position: 'absolute',
        bottom: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000
      }}>
        <ButtonCustom label={"Inizia"} click={() => setPlaying(true)} />
      </div>
    </>
  );
};

export const StartVideoScreen: ScreenElement = {
  fn: StartVideo
};
