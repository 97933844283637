import React from 'react'
import scratch from '../assets/images/layout/scratch.png';
import logo from '../assets/images/layout/logo.png';
import { animated, useSpring } from 'react-spring';
import { inFromLeft } from '../utils/springAnimations';

type Props = {
  continue: () => void,
  text: string,
  title?: string,
  underline?: string
}

export default function InfoPage(props: Props) {

  const logoScale = useSpring({
    from: { transform: 'scale(5)' },
    to: { transform: 'scale(1)' },
  });
  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(5%)' },
    to: { opacity: 1, transform: 'translateY(0%)' },
    delay: 200
  });
  const titleAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(5%)' },
    to: { opacity: 1, transform: 'translateY(0%)' },
  });

  const stripeIn = useSpring({
    ...inFromLeft,
});

  return (
    <React.Fragment>
      <div className='game-box inner p-4 info-page'>
        <div className="position-relative" style={{height: "calc(100% - 4rem)" }}>
          <animated.img className="logo" src={logo} alt="logo gameon" style={logoScale} />
          <div className="w-50 position-relative" style={{ zIndex: 3, height:'100%', overflowY: 'auto'}}>
            <animated.p className='mb-1 color-secondary-400' style={titleAnimation}>{props.title ?? ""}</animated.p>
            <animated.p className='text-white mb-1' style={textAnimation}>{props.text}</animated.p>
          </div>
          <animated.div className="stripe d-flex align-items-center" style={stripeIn}>
            <p className='pl-4 w-50 text-underline-custom'>{props.underline ?? ""}</p>
            <div className="icon-arrow-right" onClick={props.continue}></div>
          </animated.div>
        </div>
        <img className="bg-scratch" src={scratch} alt="" />
      </div>
    </React.Fragment>
  )
}