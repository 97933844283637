import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { handlePointsAtom } from "../../atoms/pointsAtom";
import VideoChoice from "../../components/VideoChoice";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { chapter51ComingUpAtom, completedChaptersAtom } from "../../atoms/chaptersAtom";
import { ChapterSelectionScreen } from "../../components/ChapterSelection";
import { listenToSabriAtom } from "../../atoms/choicesAtom";

export function ListenToSabriChoice(props: ScreenProps) {
  const setListen = useSetAtom(listenToSabriAtom)
  const setChapter51 = useSetAtom(chapter51ComingUpAtom);

  const [completedChapters, setCompletedChapters] = useAtom(completedChaptersAtom);
  const setPoints = useSetAtom(handlePointsAtom);
  const choiceA: string = chapter4Text["cap04-02-01-option-01"].text;
  const choiceB: string = chapter4Text["cap04-02-01-option-02"].text;

  function handleChoiceSelected(choice: string) {

    if (choice === choiceA) {
      setPoints(parseInt(chapter4Text["cap04-02-01-option-01"].value));
      setChapter51(true);
      setListen(true)
      if(!completedChapters.includes(3)){
        setCompletedChapters([...completedChapters, 3]);
        localStorage.setItem("completedChapters", JSON.stringify([...completedChapters, 3]));
      }
      props.onNext(ChapterSelectionScreen);
    } else {
      setListen(false)
      setChapter51(false)
      setPoints(parseInt(chapter4Text["cap04-02-01-option-02"].value));
      if(!completedChapters.includes(3)){
        setCompletedChapters([...completedChapters, 3]);
      }
      props.onNext(ChapterSelectionScreen);
    }
  }

  return (
    <VideoChoice
      videoSrc={require(`../../assets/videos/chapter4/capitolo_4_Antonio_03.mp4`)}
      choiceDelay={3}
      mirrored={true}
      question={chapter4Text["cap04-02-01-scelta_txt"].text}
      choiceSelected={handleChoiceSelected}
      choice={{
        choiceA,
        choiceB,
      }}
    />
  );
}

export const ListenToSabriChoiceScreen: ScreenElement = {
  fn: ListenToSabriChoice,
};
