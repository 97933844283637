import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap';
import './App.css';
import { TitleScreen } from './components/TitlePage';
import { useAtomValue } from 'jotai';
import { getBrightnessSettingAtom, settingsVisibleAtom } from './atoms/settingsAtom';
import Settings from './components/Settings';
import { pointsAtom } from './atoms/pointsAtom';
import ButtonCustom from './components/ButtonCustom';
import TextIntro from './components/TextIntro';
import ReactGA from 'react-ga';
// @ts-ignore 
import CookieBot from 'react-cookiebot';
import isDev from './utils/isDev';
import CharaSection from './components/CharaSection';


const upScalingFactor = 1.5;

ReactGA.initialize('G-NGYGHZHVRZ');
function App() {
  const brightness = useAtomValue(getBrightnessSettingAtom);
  const inputEl = useRef<HTMLDivElement>(null);
  const [scaleFactor, setScaleFactor] = useState<number>(Math.min((window.innerWidth / 667), (window.innerHeight / 375)));
  const [step, setStep] = useState<ScreenElement>(TitleScreen);
  const settingsOn = useAtomValue<boolean>(settingsVisibleAtom);
  const [introSelected, setIntroSelected] = useState<string>('');
  const [sectionSelected, setSectionSelected] = useState<boolean>(false);
  const points = useAtomValue(pointsAtom);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    console.log('points', points);
  }, [points]);

  const CurrentScreen = step.fn;

  // calculates the scaleFactor for the game-box element
  function windowResizeHandler() {
    if (inputEl && inputEl.current) {
      const scaleFactor = Math.min((window.innerWidth / 667), (window.innerHeight / 375));

      if (window.innerWidth < 667 || window.innerHeight < 375) {
        setScaleFactor(scaleFactor);
      } else {
        if (window.innerWidth > 667 * upScalingFactor && window.innerHeight > 375 * upScalingFactor) {

          setScaleFactor(upScalingFactor);
        } else {
          setScaleFactor(1);
        }
      }

    }

    window.removeEventListener('resize', windowResizeHandler);

    window.addEventListener('resize', windowResizeHandler);
  }

  function playHandler() {
    if (!isDev()) {
      const myEvent = new CustomEvent("game_start", {
        detail: {},
        bubbles: true,
        cancelable: true,
        composed: false,
      });

      document.dispatchEvent(myEvent);
    }


    setSectionSelected(true);
    setIntroSelected('Gioca');
  }
  function backFromSection() {
    setIntroSelected('');
    setSectionSelected(false);
  }

  function selectSection(section: string) {
    setIntroSelected(section);
    setSectionSelected(true);

    // remove the focus from the button
    const btn = document.querySelector('.to-complete');
    if (btn) {
      //@ts-ignore
      btn.blur();
    }
  }

  useEffect(() => {
    if (introSelected === 'Gioca') {
      windowResizeHandler();
    }
  }, [introSelected])

  return (
    <>
      <CookieBot domainGroupId={"c5489a73-bcd0-48bf-94f6-bb89c847307b"} language="ES" />
      <div className="loghi">
        <img src={require('./assets/images/layout/sirLogo.png')} alt="logo sir" />
        <img src={require('./assets/images/layout/kidLogo.png')} alt="logo con i bambini" />
        <img src={require('./assets/images/layout/gameLogo.png')} alt="logo gameon" />
        <img src={require('./assets/images/layout/mzLogo.png')} alt="logo melazeta" />
      </div>
      {introSelected !== 'Gioca' && <div className="preview-page">
        <div className="stripe">
          <img className="logo" src={require('./assets/images/layout/logo.png')} alt="logo gameon" />
        </div>
        {/* Intro with buttons */}
        <div className={`bg-content home ${sectionSelected ? 'open' : ''}`}>
          <ButtonCustom label='Storia' classes='to-complete' click={() => selectSection('storia')} />
          <ButtonCustom label='Personaggi' classes='to-complete' click={() => selectSection('Personaggi')} />
          <ButtonCustom label='Gioca' classes='to-complete' click={playHandler} />
        </div>

        {introSelected === 'storia' && <div className="wrapper-content-preview">
          <TextIntro click={() => backFromSection()} classes='' label='Tre amici, si conoscono da quando sono piccoli e vivono nello stesso quartiere. Insieme vivono la strada, le loro passioni e condividono i propri guai. 
          Un pomeriggio in cui i ragazzi si stanno annoiando, Antonio è appena uscito da scuola e ha la sua borsa dei disegni con sé. A un certo punto tira fuori un paio di bombolette spray e, seguendo le ispirazioni che gli danno gli amici, inizia ad abbozzare un disegno, ma il custode pare che li abbia scoperti...' />
        </div>}


        {introSelected === 'Personaggi' && <div className="main-rows-avatars">
          <div onClick={() => backFromSection()} className="wrapper-back">
            <div className="icon-back white mr-2"></div>
            <span >Back</span>
          </div>
          <CharaSection />
        </div>}
      </div>}
      {introSelected === 'Gioca' && <div className="game-box" ref={inputEl} style={{ transform: `scale(${scaleFactor}) translate(-50%, -50%)`, display: 'block', backgroundColor: '#fff', filter: `brightness(${brightness})` }}>

        {settingsOn ? <Settings /> : <CurrentScreen onNext={setStep} />}
      </div>}
    </>
  );
}

export default App;
