import React from "react";
import { messageType } from "../../components/Chat";
import VideoGroupChat from "../../components/VideoGroupChat";
import chapter4Text from "../../assets/texts/Capitolo 4.json";
import { PreGunInfoScreen } from "./PreGunInfo";

export function LeaveEnvelopeChat(props: ScreenProps) {
  return (
    <VideoGroupChat
      videoSrc="chapter4/capitolo_4_Antonio_07.mp4"
      sender={"ALESSANDRO, YOUSSEF"}
      receiver={"ANTONIO"}
      messages={messages}
      phoneDelay={3}
      mirrored={true}
      canContinue={() => props.onNext(PreGunInfoScreen)}
    />
  );
}

export const LeaveEnvelopeChatScreen: ScreenElement = {
  fn: LeaveEnvelopeChat,
};


const messages: messageType[] = [
  {
    txt: chapter4Text["cap04-02-option-01_txt01"].text,
    sender: chapter4Text["cap04-02-option-01_txt01"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt02"].text,
    sender: chapter4Text["cap04-02-option-01_txt02"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt03"].text,
    sender: chapter4Text["cap04-02-option-01_txt03"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt04"].text,
    sender: chapter4Text["cap04-02-option-01_txt04"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt05"].text,
    sender: chapter4Text["cap04-02-option-01_txt05"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt06"].text,
    sender: chapter4Text["cap04-02-option-01_txt06"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt07"].text,
    sender: chapter4Text["cap04-02-option-01_txt07"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt08"].text,
    sender: chapter4Text["cap04-02-option-01_txt08"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt09"].text,
    sender: chapter4Text["cap04-02-option-01_txt09"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt10"].text,
    sender: chapter4Text["cap04-02-option-01_txt10"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt11"].text,
    sender: chapter4Text["cap04-02-option-01_txt11"].chara
  },
  {
    txt: chapter4Text["cap04-02-option-01_txt12"].text,
    sender: chapter4Text["cap04-02-option-01_txt12"].chara
  },
]