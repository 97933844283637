import { useAtom } from "jotai";
import React from "react";
import { completedChaptersAtom } from "../../atoms/chaptersAtom";
import { ChapterSelectionScreen } from "../../components/ChapterSelection";
import Video from "../../components/Video";

export function AudioMessage(props: ScreenProps) {
  const [completedChapters, setCompletedChapters] = useAtom(completedChaptersAtom);
  return (
    <Video
      videoSrc={require(`../../assets/videos/chapter2/Capitolo_2_07_messaggio audio(fine cap 2).mp4`)}
      videoEnded={() =>{
        if(!completedChapters.includes(1)){
          setCompletedChapters([...completedChapters, 1]);
          localStorage.setItem("completedChapters", JSON.stringify([...completedChapters, 1]));
        }
        props.onNext(ChapterSelectionScreen);
      }}
    />
  );
}

export const AudioMessageScreen: ScreenElement = {
  fn: AudioMessage,
};
