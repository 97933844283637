import React from 'react'
import ButtonCustom from './ButtonCustom';

export type choiceType = {
  answerInfo: { label: string, effect: string },
  select: () => void,
}

type Props = {
  portrait: string,
  question: string,
  choiceA: string,
  choiceB: string,
  choiceDone:(choice:string)=>void,
}

export default function ChoiceOverlay(props: Props) {


  return (
    <React.Fragment>
      <div className="choice" style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, background: 'rgba(0,0,0,0.5)', zIndex:3 }}>
        <div style={buttonStyle}>
          <ButtonCustom label={props.choiceA} click={() => props.choiceDone(props.choiceA)} style={{marginBottom: "0.4rem"}} />
          <ButtonCustom label={props.choiceB} click={() => props.choiceDone(props.choiceB)} />
        </div>
        <div className="stripe d-flex justify-content-end" style={{width:'100%', left:0}}>
          <p className='pl-4 text-end'>{props.question}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

const buttonStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '1rem',
  left: '4rem',
  zIndex: '4'
}
