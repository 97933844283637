import React from "react"
import InfoPage from "../../components/InfoPage"
import chapter1Text from "../../assets/texts/Capitolo 1.json";
import { BullyChatNextScreen } from "./BullyChatNext";

function BullyChatNextIntro(props: ScreenProps) {
    return (
        <InfoPage
            continue={() => props.onNext(BullyChatNextScreen)}
            text={chapter1Text["cap01-04_txt01"].text}
            />
    )
};

export const BullyChatNextIntroScreen: ScreenElement = {
    fn: BullyChatNextIntro
}
